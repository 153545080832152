// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  months: {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  },
  not_show_today: 'Do not show this again today',
  not_show_again: 'Do not show this again',
  server_error: 'An error occurred',
  feature_not_available: 'Feature is currently unavailable',
  referral_link: 'Referral link',
  usage_error: 'Please renew/purchase the package to continue using it',
  update_extension: 'Please update to the latest version of the extension',
  upload_media_error: 'Got problem when we try to upload media',
  empty: 'No data',
  complete_infomation: 'Please complete your information for the best experience.',
  btn: {
    fix: 'Fix',
  },
  error: {
    required: 'This is a required field',
    format: {
      email: 'Must enter correct email format',
      phone: 'Must enter the correct phone number format',
    },
  },
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  commons: {
    day: 'd',
    hour: 'h',
    minute: 'm',
    second: 's',
    package: 'Package',
    expired: 'Your {{name}} has expired',
  },
  modal: {
    btn: {
      back: 'Back',
      close: 'Close',
      done: 'Done',
      next: 'Next',
    },
    popup: {
      linking_again: 'Linking Again',
    },
    title: {
      error_linking: 'Error linking account',
    },
  },
  image_upload: {
    upload: 'Upload here',
    delete: 'Delete all',
    update: 'Update image',
  },
  pwa: {
    title: 'Notification Feature',
    step_one: `1. Click the 'Share' button`,
    step_two: `2. Select 'Add to Homescreen'`,
    content: `
      1.Access your website updates on your Safari browser on Apple mobile devices 16.4 or later.
      2.Click the Share button on Safari browser.
      3.Click the Add to Home screen option.
      4.Save the app on your device.
      5.Open the app from the home screen.
      6.Sign up for notifications to use our new feature.
    `,
  },
  form: {
    invalid_format: 'Please enter the correct format',
    min: 'Enter at least {{min}} characters',
    max: 'Enter maximum {{max}} characters',
    min_number: 'The number must be larger {{min}}',
    required: 'This is a required field',
    array: {
      min: 'Must be at least {{min}}',
      max: 'Max {{max}}',
    },
    image: {
      min: 'Upload at least {{min}} photos',
      max: 'upload maximum {{max}} photos',
    },
    nudity: 'Image contains nudity',
  },
  source_filter: {
    private: 'Your source',
    organization: 'Organization',
    public: 'Shared',
    legit: 'Legit',
    exclusive: 'Exclusive',
    all: 'All',
    btn: {
      apply: 'Apply',
      reset: 'Reset',
    },
  },
  partnership: {
    title: 'Partnership',
    content: 'Enjoy value, efficiency, and discount with a partner account within the Uptin ecosystem.',
  },
  progress: 'In progress, please wait a few seconds!',
  feature_disable: 'You cannot use this feature',
  asset_required: 'Please save content first before using this feature',
  task_running: 'Same process was running before please wait!',
  login: {
    main_title: 'End-to-End marketing platform',
    heading: 'Login - Register Uptin',
    heading_tenant: 'Login {{name}}',
    sub_heading: 'Log in or register with',
    by_email: 'Login with email',
    remember: 'Remember me',
    forgot: 'Forgot password',
    test_account: 'Demo account: {{email}} / Password: {{password}}',
    or: 'OR',
    btn: {
      login: 'Login',
    },
    form: {
      password: 'Password',
    },
    message: {
      invalid: 'Email or password is incorrect',
      suggest: 'You can sign in with Google instead of Email and Password',
      failure: 'Failed to login',
    },
  },
  forgot_password: {
    heading: 'Forgot password?',
    description:
      'Please enter the email address associated with your account, we will send a link to reset your password.',
    form: {
      error: {
        email: {
          required: 'Email is required',
          format: 'Must enter the correct email format',
        },
      },
    },
    btn: {
      reset: 'Reset Password',
      back: 'Back',
    },
  },
  reset_password: {
    title: 'Request sent successfully!',
    form: {
      field: {
        password: 'Password',
        re_password: 'Re-password',
      },
      error: {
        password: {
          required: 'Password is required',
        },
        re_password: {
          required: 'Re-entering password is required',
          match: 'Re-entered password does not match',
        },
      },
    },
    btn: {
      change: 'Change Password',
    },
  },
  confirm_staff: {
    title: 'Testing in progress...',
  },
  nav: {
    general: `Dashboard`,
    asset: `Content`,
    calendar: `Calendar`,
    analytic: `Market`,
    push_notification: `Push notification`,
    email_marketing: `Email marketing`,
    create_asset: `Create content`,
    blog: `Blog`,
    real_estate: `Real estate`,
    social: `Social media`,
    web: 'Website',
    account: `Account`,
    setting: `Setting`,
    payment: `Payment`,
    change_password: `Change password`,
    logout: `Logout`,
    staff: 'Staff',
    campaign: 'Campaign',
    feed: 'Feed',
    multi_channel: 'Multichannel',
    advertisement: 'Advertisement',
    advertisement_new: 'New',
    advertisement_manage: 'Manage',
    post: 'Post & Comment',
    up_post: 'Up Post',
    lead: 'Search for customers',
    earn: 'Earn money',
    usage: 'Usage',
    interactive: 'Interact',
    faqs: 'Faq',
    support: 'Support',
  },
  header: {
    notification: {
      notification: 'Notification',
      unread: `You have # unread notifications`,
      all_notification: 'View all notifications',
    },
    task: {
      view_result: 'Click to see results',
      empty: 'No results',
      task_completed: 'Some progress has been completed!',
    },
  },
  component: {
    delete_account: {
      content: `, what happens when you "<span style="font-weight: bold">Delete account</span>" ?`,
      note: {
        first: 'Uptin only deletes login accounts',
        second: 'Accounts linked to platforms still retain',
      },
      btn: {
        back: 'Back',
        confirm: 'Confirm',
      },
    },
    trial: {
      group: 'Are you working in a group?',
      enterprise: 'Are you a business owner?',
      try_package: 'Try business package',
      try_seven_days: 'Trial {{days}} days',
      content: 'Your account balance can be used to pay later.',
      btn: {
        accept: 'Agree',
        cancel: 'Cancel',
      },
    },
  },
  dashboard: {
    app: {
      welcome: 'Welcome!',
      content: 'Choose to post or do tasks to receive FREE money.',
      btn: {
        post: 'Post now',
        linking: 'Linking now',
        quest: 'Daily tasks',
        subscribe: 'Subscribe Now',
        zalo: 'Contact Zalo',
      },
      service_mkt: {
        title: 'Full package of outsourcing Marketing department services only 100USD/month.',
        content: `
        <ol>
        <li>Build <b>10 loyal </b>customer communities around your products/services</li>
        <li>
        Feed, take care:
          <ul style="margin-left: 24px">
            <li>Write up to <b> 300 articles </b> of SEO quality every month.</li>
            <li>Take care of the Blog on your website with 1 article per day.</li>
            <li><b>Seeding</b> into communities that span the reality of participation.</li>
          </ul>
        </li>
        <li>Support for posting free news on Zalo, Group, Marketplace.</li>
        <li>Report every day.</li>
      </ol>
        `,
      },
      tab: {
        day: 'Day',
        month: 'Month',
      },
      chart: {
        filter: {
          by: 'By',
          week: 'Week',
          month: 'Month',
          year: 'Year',
        },
        total_post: 'Total post',
        view: 'View',
        display: 'Impressions',
        statistics: 'Monthly statistics',
        statistics_posts: 'Monthly statistics on number of posts',
        statistics_audience: 'Monthly statistics on number of audience',
        statistics_sub: 'compared with the previous month',
        spend: 'Spending Statistics',
        schedule_success: 'Successful post',
        schedule_fail: 'Failed post',
        balance: 'Balance',
        payment: 'Top-up',
        disburse: 'Disburse',
        sell: 'Sell',
        rent: 'Rent',
      },
    },
    account: {
      save: 'Save changes',
      send_request: 'Send request',
      breadcrumb: {
        home: 'Dashboard',
        user: 'User',
        setting: 'Setting',
      },
      tab: {
        general: 'General',
        payment: 'Payment',
        sme: 'Business package',
        notification: 'Notification',
        social: 'Social Media',
        password: 'Password',
      },
      general: {
        avatar: {
          upload: 'Upload here',
          update_type: '*.jpeg, *.jpg, *.png . images allowed',
          update_size: 'Maximum capacity 1Mb',
        },
        public: 'Public profile',
        delete: 'Delete account',
        form: {
          first_name: 'First name',
          last_name: 'Last name',
          phone: 'Phone number',
          city: 'City',
          district: 'District',
          company: 'Company',
          position: 'Position',
          persona: 'Persona',
          business_profile: 'Business Profile',
        },
        required: 'Please fill in here',
        error: 'Update error!',
        success: 'Update successful!',
        delete_account: 'Account deletion succeeded! The system will return to the login page in a few seconds!',
      },
      payment: {
        recharge: 'Recharge',
        payment: 'Payment',
        method: 'Payment methods',
        domestic_wired: 'Wired',
        wallet: 'Wallet',
        history: 'History',
        empty: 'Empty',
        add_method: 'Add new method payment',
        topup: 'Top-up',
        proceed: 'Proceed',
        package: 'Package',
        sme: {
          member: {
            exact: '{{numb}} users',
            range: '{{from}} - {{to}} users',
            smaller: 'Below {{numb}} users',
            larger: '{{numb}}+ users',
          },
          target: {
            group: 'For groups',
            sme: 'For SME',
            enterprise: 'For large enterprise',
          },
          month: 'Month',
          year: 'Year',
          discount: ' (Save {{percent}}%)',
        },
      },
      notifications: {
        activity: {
          comment: 'Email me when someone comments on my post',
          feeling: 'Email me when someone reacts to my post',
          follow: 'Email me when someone follows me',
        },
        application: {
          update: 'System news and announcements',
          blog: 'Weekly software updates and new blog posts',
        },
        success: 'Update completed!',
      },
      change_password: {
        old_password: 'Current password',
        new_password: 'New password',
        re_new_password: 'Repeat new password',
        error: {
          old_password_required: 'Old password is required',
          new_password_required: 'New password is required',
          new_password_length: 'New password must be at least 6 characters',
          re_new_password: 'Re-entered password does not match',
        },
      },
    },
    analytic: {
      location: 'Location',
      breadcrumb: {
        market: 'Market',
        overview: 'Dashboard',
      },
      form: {
        city: 'City',
        district: 'District',
        ward: 'Ward',
        error: {
          required: 'Please fill in here',
        },
      },
      btn: {
        search: 'Analytic',
      },
      tab: {
        sell: 'Sell',
        rent: 'Rent',
        facade: 'Facade',
        alley: 'In the alley',
      },
      chart: {
        compare: 'compared to the previous month',
        title: 'Market in 3 years',
        average_price: 'Average price',
        number_posts: 'Number posts',
      },
      average_price: 'Average price',
      number_posts: 'Number posts',
      compare: 'compared to the previous quarter',
      helps: 'Analytics helps you',
      develop: 'This feature is in development.',
    },
    asset: {
      breadcrumb: {
        asset: 'Content',
        overview: 'Dashboard',
      },
      stat: {
        success: 'Success',
        scheduled: 'Scheduled',
        total: 'Total posts',
        month: 'post in month',
        sum: 'total post',
        post_total: 'Total posts',
        post_month: 'post in month',
        post_sum: 'total post',
      },
      btn: {
        remove_filter: 'Reset',
        real_estate: 'Real estate',
        social: 'Social media',
        edit: 'Edit',
        schedule: 'Post',
        detail: 'Detail',
        web: 'Website',
        converts: {
          mxh: 'Clone to SOCIAL with AI',
          web: 'Clone to WEBSITE with AI',
          pnt: 'Clone to PNT with AI',
        },
      },
      filter: {
        type: 'Type',
        start_day: 'Start day',
        end_day: 'End day',
        search: 'Search by title',
      },
      table: {
        head: {
          type: 'Type',
          avatar: 'Avatar',
          title: 'Title',
          city: 'Location',
          created_day: 'Created at',
          updated_day: 'Updated at',
        },
      },
      detail: {
        title: 'Detail post',
        table: {
          head: {
            platform: 'Platform',
            title: 'Title',
            date: 'Date',
            display: 'Display',
            status: 'Status',
            view: 'View',
            impression: 'Impression',
          },
        },
        tab: {
          all: 'All',
          active: 'Active',
          inactive: 'Inactive',
          hidden: 'Hidden',
          remove: 'Removed',
        },
        btn: {
          back: 'Back',
          link: 'Go to the post',
        },
        dialog: 'Filter post',
        notification: 'Notification',
        collapse: 'Collapse',
      },
      message: {
        cloned: 'This post has been cloned',
      },
    },
    calendar: {
      form: {
        table: {
          head: {
            platform: 'Platform',
            id_balance: 'ID/Balance',
            select_package: 'Select package',
            title_balance: 'Title/Balance',
            note: 'Note',
            package: 'Package',
            date_post: 'Date post',
            day_post: 'Day post',
            time_post: 'Time post',
            name_avatar: 'Name/Avatar',
          },
          body: {
            your_page: 'Your page',
            your_group: 'Your group',
            other_group: "Someone else's group",
            personal: 'Personal',
            organization: 'Organization',
            instagram_page: 'Instagram page',
          },
        },
        btn: {
          edit: 'Edit',
          back: 'Back',
          done: 'Done',
          link: 'Go to post',
        },
        tab: {
          real_estate: 'Real estate',
          social: 'Social media',
          web: 'Website',
        },
        current_asset: 'Current content',
        spam: "We add a 5 minute delay between messages so you don't get marked as spam",
        collapse: 'Collapse',
        type: 'Type',
      },
      breadcrumb: {
        home: 'Dashboard',
        calendar: 'Calendar',
      },
      btn: {
        schedule: 'Schedule',
      },
      title: {
        dialog: 'Filter post',
        notification: 'Notification',
      },
      message: {
        selects: 'Choose at least 1 platform to post news',
        invalid_time: 'Please select the correct scheduled date',
      },
    },
    site: {
      action: {
        link_account: 'real estate platform',
      },
      breadcrumb: {
        home: 'Dashboard',
        account: 'Account',
      },
      dialog: {
        cms_title: 'Linking CMS platform',
      },
      popup: {
        title: 'GOOGLE API DISCLOSURE',
        content: `This app complies with the Google API Services User Data Policy, including the Limited Use requirements. This means that we will only use the data you provide us with to provide the services you have requested, and we will not share your data with any third parties without your consent.</br></br>You can learn more about the Google API Services User Data Policy here: <a href='https://developers.google.com/terms/api-services-user-data-policy' target='_blank'>https://developers.google.com/terms/api-services-user-data-policy</a>.</br></br>If you have any questions or concerns about our privacy practices, please contact us at: admin@uptin.vn</br></br>Thank you for using our app!`,
      },
      popup_remove: {
        title: 'Account deletion confirmation',
        content: `Are you sure you want to delete this account?`,
      },
      btn: {
        add: 'Add',
        real_estate: 'Real estate',
        social: 'Social media',
        collapse: 'Collapse',
        view: 'View',
        edit: 'Edit',
        connect: 'Connect',
        close: 'Close',
        save: 'Check and save',
        analytic: 'Google Analytics (GA4)',
        confirm: 'Confirm',
        cancel: 'Cancel',
        remove: 'Delete',
      },
      tab: {
        all: 'All',
        real_estate: 'Real estate',
        social: 'Social media',
        website: 'CMS',
      },
      form: {
        table: {
          head: {
            platform: 'Platform',
            balance_account_url: 'Balance/Name account/Url',
            name: 'Name account',
            status: 'Status',
            name_avatar: 'Name/Avatar',
          },
          body: {
            connected: 'Connected',
            disconnect: 'Disconnect',
          },
        },
        field: {
          password: 'Password',
          app_password: 'Application password',
          access_token: 'Access token',
          website: 'Website url',
          blog_url: 'Blog url',
          username: 'Username',
          is_branch: 'Sub-Account/Business',
          allow_create: 'Auto create',
          phone: 'Telephone number',
          name: 'Fullname',
        },
        error: {
          required: 'Please fill in here',
          url_invalid: 'Please fill valid url',
        },
      },
      message: {
        site_inactive: '{{name}} is temporarily closed',
        analytic_success: 'Integrated google analytic successfully',
        remove_success: 'Removed account successfully',
      },
    },
    social: {
      popup_facebook: {
        title: 'FACEBOOK API DISCLOSURE',
        content: `
        <ol>
        <li>Authentication and Authorization: Uptin would integrate Meta's authentication mechanisms to allow users to log in or connect their Meta accounts securely. This involves obtaining the necessary permissions from users to access their profile and manage their groups and pages.</li>
  <li>Group API Integration: Uptin would utilize the Group API to retrieve a list of groups and pages the user is a member of or is an admin.</li>
  <li>Utilizing publish_to_groups Endpoint: Uptin might incorporate the publish_to_groups endpoint to enable users to post content to specific groups or pages through their platform. This involves:</li>
  </ol>
  <ul>
  <li>Allowing users to compose and schedule posts or media.</li>
  <li>Facilitating the selection of groups where the content will be published.</li>
  <li>Ensuring that the content created complies with Meta's community standards and policies before initiating the publishing process.</li>
  <li>Receive your email address</li>
  <li>Access profile and posts from the Instagram account connected to your Page</li>
  <li>Upload media and create posts for the Instagram account connected to your Page</li>
  <li>Post content into groups on your behalf</li>
  <li>Create and manage content on your Page</li>
  <li>Read content posted on the Page</li>
  <li>Read user content on your Page</li>
  <li>Show a list of the Pages you manage</li>
  </ul>`,
      },
      platform: 'Add social media platform',
      message: {
        empty_page: 'You have not joined any pages yet',
        empty_group: 'You have not joined any groups yet',
        empty_installed: `Couldn't find information about groups that added the Uptin app`,
        empty_personal: `Couldn't find information about personal`,
        empty_organization: `Couldn't find information about organizations`,
      },
      tab: {
        fanpage: 'Fanpage',
        own_group: 'Your group',
        installed_group: 'The group connected to Uptin',
        personal: 'Personal',
        organization: 'Organizations',
      },
      btn: {
        prev: 'Previous',
        next: 'Next',
        back: 'Back',
        copy_link: 'Copy group link',
        auto_linking: 'Auto linking',
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
      form: {
        search: 'Search by group name or tag...',
      },
      snackbar: {
        connected: 'Established connection',
        copied: 'Copied path!',
        removed: 'Removed connection',
      },
      popup: {
        content: `To be able to post to the group “<span style="font-weight: bold">#</span>", you need to contact the Group Administrator and ask them to <span style="font-weight: bold">add Uptin app</span> in the App section of the group. Find the group on facebook or copy this link and paste it in your browser.`,
        remove_content: `Are you sure that you want to remove the connected social from Uptin?`,
      },
    },
    create_asset: {
      breadcrumb: {
        home: 'Dashboard',
        asset: 'Content',
        create_asset: 'Create content',
        edit_asset: 'Edit content',
      },
      message: {
        form_invalid: 'Please fill in all information',
      },
      real_estate: {
        title_create: 'Create real estate content',
        title_edit: 'Edit real estate content',
        tab: {
          general: 'General information',
          detail: 'Detail information',
        },
        btn: {
          next_step: 'Next step',
          next: 'Next',
          save: 'Save',
          complete: '{{action}} and schedule',
          edit: 'Update',
          create: 'Create',
          amenitie: 'Near amenities',
          convince: 'Convince',
          back: 'Back',
          close: 'Close',
          add_amenitie: 'Add to description',
        },
        message: {
          create_success: 'Create content successfully',
          edit_success: 'Update content successfully',
          check_error: 'Please double check the information: ',
        },
        form: {
          field: {
            asset_type: 'Type of real estate',
            asset_detail: 'Real estate details',
            city: 'City',
            district: 'District',
            ward: 'Ward',
            street: 'Street',
            price: 'Price (VNĐ)',
            roi_percentage: 'Return Of Investment %',
            area: 'Area (m2)',
            actual_area: 'Usable area (m2)',
            url: 'Url',
            share_source: {
              title: 'Shared source',
              private: 'Private',
              organization: 'Organization',
              public: 'Public',
            },
            demand: {
              title: 'Demand*',
              sell: 'Sell',
              rent: 'Lease',
              buy: 'Buy',
              tenant: 'Rent',
              assign: 'Assign',
            },
            who: {
              title: 'You are*',
              broker: 'Agency',
              owner: 'Owner',
              developer: 'Developer',
              investor: 'Investor',
            },
            bedroom: 'Bedroom',
            toilet: 'Toilet',
            legal_document: 'Legal documents',
            contact_info: 'Contact info',
            address: 'Address',
            hand_overs: {
              project: 'Project',
              under_construction: 'Under construction',
              slacking: 'Incomplete',
              handing_over: 'Handing over',
              complete: 'Complete',
              red_book: 'Red book',
              house_ownership: 'Pink book',
              sale_contract: 'Sale contract',
              valid_documents: 'Valid documents',
            },
            title: 'Title',
            short_title: 'Short (50-70 characters)',
            description: 'Detailed description (up to 3000 characters)...',
            image: {
              title: 'Images',
              heading: 'Minimum 3 photos - Up to {{asset_image_count}} photos',
              first_content: '- Drag and drop or click to select photos',
              second_content: '- Maximum image size 3Mb',
              third_content: '- The first photo from left to right will be the cover photo',
            },
            hidden: 'Hidden',
            house_number_hidden: 'Hide house address number in posts',
            shown: 'Shown',
            house_number_shown: 'Show house address number in posts',
            converts: {
              mxh: 'Automatically create social media post',
              web: 'Automatically create website post',
              pnt: 'Automatically create push notification post',
            },
          },
          error: {
            select: 'Please choose',
            required: 'Please fill in here',
            price: 'Please enter the correct price',
            area: 'Please enter the correct area',
            toilet: 'Please enter the correct toilet',
            bedroom: 'Please enter the correct bedroom',
            url_invalid: 'Please fill valid url',
            roi_percentage: {
              min: 'Roi Percentage should be at least 0%',
              max: 'Roi Percentage up to 30%',
            },
            short_title: {
              min: 'Title should be at least 50 characters',
              max: 'Title should be at most 70 characters',
            },
            description: {
              min: 'Detailed description of at least 200 characters',
              max: 'Detailed description up to 3000 characters',
            },
            images: {
              min: 'Please upload at least 3 photos',
              max: 'Please upload up to {{asset_image_count}} photos',
            },
          },
        },
      },
      social: {
        title_create: 'Compose social media posts',
        title_edit: 'Editing social media posts',
        btn: {
          rewrite: 'Rewrite',
          save: 'Save',
          complete: '{{action}} and schedule',
          edit: 'Update',
          create: 'Create',
          google_format: 'Google Post Format',
          instagram_format: 'Instagram post format',
        },
        message: {
          create_success: 'Create content successfully',
          edit_success: 'Update content successfully',
          check_error: 'Please double check the information: ',
          hashtag_error: 'Add some keywords related to your problem',
        },
        form: {
          field: {
            keyword: 'Keywords "," separated by commas',
            description: 'Fill in something interesting...',
            title: 'Title',
            image: {
              title: 'Images',
              heading: 'Upload up to {{asset_image_count}} photos',
              first_content: '- Drag and drop or click to select photos',
              second_content: '- Maximum image size 3Mb',
              third_content: '- The first photo from left to right will be the cover photo',
            },
            converts: {
              mxh: 'Automatically create social media post',
              web: 'Automatically create website post',
              pnt: 'Automatically create push notification post',
            },
          },
          error: {
            required: 'Please fill in here',
            description: {
              min: 'Enter at least 50 characters',
              invalid: 'Content must be more than 50 characters (excluding emojis)',
            },
            images: {
              max: 'Please upload up to {{asset_image_count}} photos',
            },
          },
        },
      },
      web: {
        title_create: 'Compose website posts',
        title_edit: 'Editing website posts',
        btn: {
          preview: 'Preview',
          complete: 'Complete',
        },
        message: {
          create_success: 'Create content successfully',
          edit_success: 'Update content successfully',
          check_error: 'Please double check the information: ',
          hashtag_error: 'Add some keywords related to your problem',
        },
        form: {
          field: {
            title: 'Title',
            description: 'Description',
            content: 'Content',
            tags: 'Tags',
            meta_title: 'Meta title',
            meta_description: 'Meta description',
            meta_keywords: 'Keywords',
            template: 'Template',
            image: {
              title: 'Cover image',
              heading: 'Cover image',
              first_content: '-Drag and drop or click to select photos',
              second_content: '-Maximum image size 5Mb',
            },
            converts: {
              mxh: 'Automatically create social media post',
              web: 'Automatically create website post',
              pnt: 'Automatically create push notification post',
            },
          },
          error: {
            required: 'Please fill in here',
            title: {
              min: 'Title should be at least 30 characters',
              max: 'Title up to 50 characters',
            },
            description: {
              min: 'Enter at least 50 characters',
              invalid: 'Content must be more than 50 characters (excluding emojis)',
            },
            images: {
              max: 'Please upload up to 1 photos',
              min: 'Please upload at least 1 photo',
            },
            meta_keywords: {
              min: 'Keywords must have at least 1 items',
            },
            tags: {
              limit: 'The number of tags has exceeded the allowed limit',
              min: 'Tags must have at least 1 items',
            },
            template: {
              max: 'Template upto 200 characters',
            },
          },
        },
      },
    },
    notification: {
      you: 'You',
      breadcrumb: {
        general: 'Dashboard',
        notification: 'Notification',
        all_notification: 'All notifications',
      },
      menu: {
        setting: 'Notification setting',
        mark_read: 'Mark all as read',
      },
      btn: {
        collapse: 'Collapse',
      },
    },
    quest: {
      breadcrumb: {
        quest: 'Quest',
        general: 'Dashboard',
        user: 'User',
      },
      detail: 'Quest details:',
      multi: 'amount of money',
      vnd: 'VND',
      members: 'members',
      title: {
        reward_login_daily: 'Login daily',
        reward_daily_scheduled_bds: 'Post a real estate post',
        reward_daily_scheduled_mxh: 'Post a social media post',
        reward_daily_scheduled_web: 'Post a website post',
        reward_linking_account: 'Successfully linked account',
        promo_linking_facebook_group: 'Add Uptin app to Facebook group',
        promo_daily_topup: 'First recharge of the day',
        promo_first_topup: 'First deposit',
      },
      description: {
        reward_login_daily:
          '- Reward: {{moneyReceive}} VND\n- Requirements: Log in to Uptin through Google or Facebook\n- Task type: Daily',
        reward_daily_scheduled_bds:
          '- Reward: {{moneyReceive}} VND\n- Requirements: Schedule and successfully post 01 real estate listing per day\n- Task type: Daily',
        reward_daily_scheduled_mxh:
          '- Reward: {{moneyReceive}} VND\n- Requirements: Schedule and successfully post 01 social media listing per day\n- Task type: Daily',
        reward_daily_scheduled_web:
          '- Reward: {{moneyReceive}} VND\n- Requirements: Schedule and successfully post 01 website listing per day\n- Task type: Daily',
        reward_linking_account:
          '- Reward: {{moneyReceive}} VND\n- Requirements: Connect 1 real estate/social media account to Uptin for the first time and succeed.\n- Task type: One-time only',
        promo_linking_facebook_group:
          '- Reward: {{moneyReceive}} VND x Number of group members\n- Requirements: Must be the group admin and the first person to install the Uptin application in the Facebook group. The group must have over 100 members.\n- Task type: One-time only',
        promo_daily_topup:
          '- Reward: Double the deposit amount (maximum reward of 50,000 VND)\n- Requirements: Payment via Momo, VNPay, or bank transfer\n- Task type: Daily',
        promo_first_topup:
          '- Reward: Double the deposit amount (unlimited)\n- Requirements: Payment via Momo, VNPay, or bank transfer\n- Task type: One-time only',
      },
      status: {
        completed: 'Completed',
      },
    },
    staff: {
      breadcrumb: {
        staff: 'Staff',
        general: 'Overview',
        list: 'List',
      },
      table: {
        head: {
          avatar: 'Picture',
          name: 'Full name',
          status: 'Status',
          date_join: 'Join date',
          date_end: 'End date',
        },
        body: {
          pending: 'Pending',
          confirmed: 'In use',
          quit: 'Stop using',
          refuse: 'Refuse',
        },
      },
      form: {
        error: {
          required: 'Please fill in here',
          type_invalid: 'Incorrect format',
        },
      },
      btn: {
        request: 'Send request',
        add: 'Add staff',
        accept: 'Agree',
        collapse: 'Collapse',
        delete: 'Remove',
        resend: 'Resend',
      },
      delete: {
        title: 'Remove personnel {{email}} from service pack?',
        content:
          'The above user will no longer share your plan. However, you will only be able to add new staff after 7 days.',
      },
    },
    push_notification: {
      breadcrumb: {
        notification: 'Notification',
        overview: 'Dashboard',
        create_notification: 'Create notification',
      },
      tab: {
        list: 'List',
        approve: 'Pending Requests',
        audience: 'Audience',
        content: 'Content',
      },
      table: {
        head: {
          image: 'Image',
          title: 'Title',
          status: 'Status',
          budget: "Budget <br /> Recipient's",
          date_send: 'Delivery date',
          content: 'Content',
        },
      },
      form: {
        label: {
          demographic: 'Demographic',
          date_picker: 'Choose a date',
        },
        field: {
          area: {
            title: 'Area',
            northern: 'Northern',
            central: 'Central',
            southern: 'Southern',
          },
          city: 'City',
          career: {
            title: 'Carrer',
            broker: 'Broker',
            owner: 'Owner',
            developer: 'Developer',
            investor: 'Investor',
          },
          asset_type: 'Type of real estate',
          asset_detail: 'Real estate details',
          demand: {
            title: 'Demand',
            sell: 'Sell',
            rent: 'Lease',
            buy: 'Buy',
            tenant: 'Rent',
            assign: 'Assign',
          },
          all: 'All',
          image: {
            heading: 'Upload image',
            first_content: '-Drag and drop or click to select',
            second_content: '-Maximum image size 5Mb',
            third_content: '-The image is 256x256 px',
          },
          title: 'Title(6-80 characters)',
          content: 'Content(10-150 characters)',
          link: 'Notification link',
        },
        error: {
          required: 'Please fill in here',
          image: {
            required: 'Please upload a photo',
            only_one: 'Please upload 1 photo',
            format: 'Image must be 256x256 px',
          },
          title: {
            min: 'Enter at least 6 characters',
            max: 'Enter up to 80 characters',
          },
          content: {
            min: 'Enter at least 10 characters',
            max: 'Enter up to 150 characters',
          },
          link: {
            format: 'Please enter the correct link format',
          },
        },
      },
      preview: 'Preview',
      btn: {
        delete: 'Delete',
        approve: 'Approve',
        reject: 'Reject',
        collapse: 'Collapse',
        create_notification: 'Create notification',
        next: 'Next step',
        back: 'Back',
        complete: 'Submit',
      },
      status: {
        deliveried: 'Deliveried',
        canceled: 'Denied',
        pending: 'Pending',
        scheduled: 'Scheduled',
      },
      message: {
        create_success: 'Created notification success',
        deleted_success: 'Deleted success notification',
        deleted_fail: 'Deleted failure notification',
        approved_success: 'Approved success notification',
        approved_fail: 'Approve failed notification',
        denied_success: 'Rejected success notification',
        denied_fail: 'Reject failure notification',
      },
    },
    multi_channel: {
      steps: {
        source: 'Source',
        content: 'Content',
        channels: 'Channel',
        schedule: 'Schedule',
        btn: {
          next: 'Next',
          back: 'Back',
          save: 'Save',
          skip: 'Skip',
        },
        step_1: {
          social_web: 'Social+Web Only',
          industry: 'Industry',
          exclusive: 'Exclusive',
          continue: 'Continue',
          industry_list: {
            real_estate: 'Real estate',
            ecommerce: 'Ecommerce',
          },
          level: 'Mode',
          source_name: 'Source name',
          data_from: 'Get data from',
          form: {
            images: {
              title: 'Upload 1 photo',
              text1: '- Drag and drop or click to select image',
              text2: '- Maximum image size 3Mb',
              text3: '- Representative image of the source',
            },
            errors: {
              required: 'This is a required field',
              images: {
                min: 'Select at least 1 photo',
                max: 'Select up to 1 photo',
              },
            },
          },
          message: {
            select_source: 'Please select a source',
            create_source_success: 'Successfully added source',
          },
          btn: {
            add: 'Add source',
          },
        },
        step_3: {
          cost: 'Estimated cost:',
          setting_name: 'Setting name',
          selected: 'Selected',
          quota: 'Quota',
          package: 'Package',
          setting: {
            delete: 'Confirm delete this setting!',
          },
          types: {
            bds: 'Real estate',
            mxh: 'Social network',
            email: 'Email marketing',
            web: 'Website/Landing pages',
          },
          form: {
            errors: {
              required: 'This is a required field',
            },
          },
          btn: {
            create_setting: 'Create setting',
            add_setting: 'Add setting',
            add_email: 'Add email',
          },
          message: {
            select_channel: 'Please select a distribution channel',
            select_package: 'Please choose the appropriate package',
            select_platform: 'Please select at least 1 platform',
            create_setting_success: 'Customization created successfully',
            deleted_success: 'Deleted successfully',
          },
          filter_tag: {
            all: 'All',
            page: 'Page',
            group: 'Group',
            other: 'Other',
            your_group: 'Your group',
            other_group: "Someone else's group",
          },
        },
        step_4: {
          right_away: 'Right away',
          later: 'Later',
          on: 'At',
          confirm: 'Confirm sending',
          message: {
            select_time: 'Please select delivery time',
          },
        },
      },
    },
    feed: {
      btn: {
        back: 'Back',
        next: 'Continue',
        save: 'Done',
        add: 'Add target',
        test: 'Test run',
        url: 'Get related links',
        preview: 'Preview',
        now: 'Post now',
        scheduled: 'Scheduled posts',
        disable: 'Disable',
        active: 'Active',
        import_data: 'Import data group',
        general_article: 'Generate Article',
        preview_seeding: 'Preview seeding contents',
        update: 'Update',
      },
      step_1: {
        title: 'Target',
        filter: {
          type: 'Type',
          keywords: 'Search by name or UID',
          all: 'All',
          page: 'Page',
          group: 'Group',
          your_group: 'Your group',
          other_group: "Someone else's group",
          website: 'Website',
          your_config: 'Your config',
        },
        add_affiliate_account: 'Add affiliate account',
        linking_social_media: 'Linking social media platform',
        linking_cms: 'Linking CMS platform',
      },
      step_2: {
        title: 'Setting',
        messages: {
          select_sources: 'Please select at least 1 source',
          double_check: 'Please check the information again',
          limit_keyword: 'Keyword maximum 1500 characters',
          select_days: 'Please select at least 1 day of the week',
          select_tones: 'Please select at least 1 rewrite tone',
          select_images: 'Please select at least 1 image source',
          created_success: 'The feed function has been successfully activated',
          server_error: 'An error occurred',
          run_feed: 'Create schedule for feed successfully',
          empty_posts_today: 'There are no posts today',
          select_page: 'Please select at least 1 account to authorize posting',
          update_persona_success: 'Persona updated successfully',
        },
        edit_persona: {
          title: 'Edit persona for page',
        },
        modal: {
          title: 'List related links',
          title_import: 'Import your CSV file',
          upload: 'Upload',
          error: 'No File Upload',
        },
        source: {
          title: 'Select sources:',
        },
        keyword: {
          title: 'Keywords:',
          extra_link: 'Data will get at:',
          select_link: 'Select this content',
        },
        recurrence: {
          title: 'Recurrence:',
        },
        form: {
          fields: {
            days: 'Days in week',
            keyword: 'Keyword',
            time: 'At',
            end: 'End',
            source: 'Source',
            use_current_account: 'Post using current account',
            mode: {
              seo: 'SEO',
              seeding: 'Seeding',
            },
            news_option: 'News Options',
            ai: {
              image: 'Image',
              tone: 'AI rewrite tone',
              random: 'Random',
              seeding: 'Seeding',
              meme: 'MEME',
              nothing: 'Only text',
              add_hashtag: 'Add Hashtag',
              add_emoji: 'Add Emoji',
              standard: 'Standard',
              funny: 'Funny',
              creative: 'Creative',
              professional: 'Professional',
              generate: 'AI create',
              search: 'Search',
              banner: 'Banner',
              block_advertisement: 'Block Advertisement',
              sharing: 'Sharing',
              target_info: 'Insert Target Infomation',
              express_opinion: 'Express opinion',
              none: 'No-Image',
              intent: 'Intent',
            },
            intent: {
              review: 'Review',
              buy: 'Buy',
              sell: 'Sell',
              rate: 'Ask for review',
              complain: 'Complain',
              ask: 'Ask',
              misc: 'Misc',
            },
            seeding_mode: {
              no_text: 'No text',
              fake_user: 'Fake user',
              no_img: 'No Image',
              collection_1: 'Collection 1',
              collection_n: 'Collection N',
              text: 'Text',
              image: 'Image',
            },
            label_target: 'Target Info',
            days_in_week: {
              monday: 'M',
              tuesday: 'T',
              wednesday: 'W',
              thursday: 'T',
              friday: 'F',
              saturday: 'S',
              sunday: 'S',
            },
            languages: {
              title: 'Language',
              vietnam: 'Vietnamese',
              english: 'English',
              espanol: 'Espanol',
              french: 'French',
              chinese: 'Chinese',
              korean: 'Korean',
              japan: 'Japanese',
            },
            article_label: {
              title: 'Title',
              short_keywords: 'Short Keyword',
              type: 'Type',
              search_volume: 'Search Volume',
              difficulty: 'Difficulty',
            },
          },
          errors: {
            required: 'This is a required field',
            days: {
              min: 'Choose at least 1 day',
            },
            tone: {
              min: 'Choose at least 1 rewrite tone',
            },
            image: {
              min: 'Choose at least 1 option',
            },
            keywords: {
              errors: 'Please  enter keywords',
              min: 'Enter at least 1 keyword',
              max: 'Enter up to 1500 characters',
              commas: 'Do not include commas in keywords field',
            },
            text: 'Choose at least 1 Text',
            seo_options: 'Choose at least 1 option news or business',
            business_keywords: 'Please choose option business',
            sharing: 'Sharing button is required',
            target_info: 'Please enter target info',
            misc: 'Please select least one collection',
            opinion: 'Please enter persona in setting account',
            article: 'Target info or Business profile in setting is required',
          },
        },
      },
      coming_soon: {
        title: 'Coming Soon',
        subtitle: 'We are working hard to develop this feature!',
      },
    },
    ads_marketing: {
      breadcrumb: {
        ads_marketing: 'Advertising',
        overview: 'Overview',
        create_notification: 'Create ad',
      },
      steps: {
        target: '1. Audience',
        content: '2. Content',
        schedule: '3. Schedule',
      },
      tab: {
        list: 'List',
        approve: 'Approve',
        audience: 'Audience',
        content: 'Content',
      },
      table: {
        head: {
          image: 'Photo',
          title: 'Title',
          status: 'Status',
          budget: 'Cost/recipient',
          date_send: 'Post date',
          content: 'Content',
          platform: 'Platform',
          name: 'Name',
          audience: 'Interested people',
          detail: 'Detail',
          persona: 'Persona',
        },
        body: {
          status: {
            approved: 'Approved',
            rejected: 'Rejected',
            scheduled: 'Scheduled',
            pending: 'Pending',
            canceled: 'Cancel',
            deliveried: 'Deliveried',
          },
        },
      },
      form: {
        label: {
          demographic: 'Demographics',
          date_picker: 'Pick date',
        },
        field: {
          gender: 'Gender',
          age: {
            title: 'Age',
            below: 'Below',
            sub_title: 'age',
            from: 'From',
            to: 'To',
            all: 'All ages',
            child: 'Under 6 years old',
            between: '{{min}}-{{max}} years old',
            middle: 'Middle-aged (45-65 years old)',
            elder: 'Elderly people',
          },
          industry: 'Industry/profession',
          nation: 'Country',
          location: 'Location',
          all: 'All',
        },
      },
      schedule: {
        post_time: 'Post time',
        list: 'Posting list',
      },
      preview: 'Preview',
      list_scheduled: 'List scheduled posts',
      btn: {
        edit: 'Edit',
        detail: 'Detail',
        delete: 'Delete',
        approve: 'Approve',
        reject: 'Reject',
        collapse: 'Collapse',
        create_notification: 'Create ad',
        next: 'Continue',
        back: 'Back',
        complete: 'Completed',
        reset: 'Reset',
        apply: 'Apply',
      },
      status: {
        deliveredied: 'Delivered',
        canceled: 'Declined',
        pending: 'Waiting for approval',
        scheduled: 'Scheduled',
      },
      message: {
        create_success: 'Created ad successfully',
        deleted_success: 'Advertisement deleted successfully',
        deleted_fail: 'Advertisement deletion failed',
        approved_success: 'Advertisement approved successfully',
        approved_fail: 'Ad approval failed',
        denied_success: 'Advertisement denied successfully',
        denied_fail: 'Advertisement denied failed',
        empty_adience: 'Recipients cannot be empty',
      },
    },
  },
  email: {
    sidebar: {
      compose: 'Compose',
      contact: 'Contact',
      campaigns: 'Campaign',
      template: 'Template',
      settings: 'Settings',
      quota: 'Usage',
    },
    btn: {
      create_campaign: 'Create campaign',
      create_group: 'Add contact group',
      create_contact: 'Add contact information',
      create_template: 'Create template',
      update: 'Update',
      create: 'Create',
      cancel: 'Cancel',
      check_verify: 'Check',
      add_verify: 'Add sender',
      delete: 'Delete',
      edit: 'Edit',
      resend: 'Resend',
      schedule: 'Schedule',
      send_now: 'Send now',
      save: 'Save',
    },
    tabs: {
      domain_verify: 'Domain verification',
      email_verify: 'Email verification',
    },
    table: {
      head: {
        domain: 'Domain',
        verified: 'Verified',
        bounce_domain: 'Bounce domain',
        email: 'Email',
      },
      body: {
        verified: 'Verified',
        unverified: 'Unverified',
      },
    },
    message: {
      error: 'Have some error happened',
      remove_success: 'Deleted successfully',
      resend_success: 'Sent successfully',
    },
    verify: {
      btn: {
        continue: 'Continue',
        completed: 'Complete',
        back: 'Back',
        verify: 'Verify record',
        value: 'Value',
        copy: 'Copy',
      },
      email: {
        btn: {
          resend: 'Resend',
        },
        form: {
          errors: {
            email_format: 'Invalid email format',
            required: 'This is required field',
          },
        },
        content: {
          step1: {
            title: 'Enter email address',
            content: 'Enter the email address you want to use as your sending email.',
          },
          step2: {
            title: 'Check mailbox',
            content: `We've sent you a verification email. Check your mailbox and click the confirmation link. If you didn't receive it, please check your other mailbox folders or click the resend button.`,
            resend: 'Resend verification email',
          },
        },
      },
      domain: {
        form: {
          errors: {
            email_format: 'Invalid email format',
            domain_format: 'Invalid domain format',
            required: 'This field is required',
          },
        },
        content: {
          dns: {
            title: 'Ok! Follow the instruction described below.',
            content: {
              spf: {
                existed: {
                  item1: `Edit the <span style="font-weight:bold">{{bold_text1}}</span> record and merge your existing
              record and merge your existing <span style="font-weight:bold">{{bold_text2}}</span> with variable display below`,
                  item2: `For example, if your domain already has the record:`,
                  item3: `So, created variable will look like this:`,
                },
                no_content: {
                  item1: `Great! Follow the instruction described below.`,
                  item2: `Create a new <span style="font-weight:bold">{{bold_text1}}</span> record`,
                  item3: `Fill in the <span style="font-weight:bold">{{bold_text1}}</span> field. Depending on the provider, you probably have to follow one of these options:`,
                  item4: `Leave it empty`,
                  item5: 'Fill it with @',
                  item6: 'Enter your domain name ({{domainName}})',
                  item7:
                    'Fill in the <span style="font-weight:bold">{{bold_text1}}</span> of this record with variable displayed below',
                },
                mainContent: {
                  item1: ` Check if you already have an <span style="font-weight:bold">{{bold_text1}}</span> record in your DNS. It should be a <span style="font-weight:bold">{{bold_text2}}</span> -type record with SPF variables (starts with "v=spf1"). This check will determine your next steps. record`,
                  item2: 'I did not find the record described above',
                  item3: 'I found the record described above',
                },
              },
              other: {
                keyword: `Create a new <span style="font-weight:bold">{{bold_text}}</span> record`,
                host: `Fill in the <span style="font-weight:bold">Name (Host)</span> field of this record with variable displayed below`,
                value: `Fill in the <span style="font-weight:bold">Value</span> field of this record with variable displayed below`,
              },
            },
          },
          step1: {
            title: 'Enter the domain you want to verify',
          },
          step2: {
            title: 'Log in to your domain provider',
            content: `To verify your domain, you need to set DNS records for your domain.`,
            list: {
              item1: `Open a new tab and navigate and <span style="font-weight:bold">{{bold_text}}</span>
                  to platform where your domain is hosted`,
              item2: `Find the screen where you can manage your domain. There will be an option to adjust your <span style="font-weight:bold">{{bold_text}}</span>.`,
              item3: `When you find it, you are ready for the next step!`,
            },
          },
          step3: {
            title: 'Add entries to your DNS settings',
            content: ` In your domain settings, you need to add record for {{domain_name}}.`,
          },
          step4: {
            title: 'Confirmation',
            content1: `Great! Your domain is verified! You can now enjoy the platform by sending your emails from any address
            associated with this domain.`,
            content2: `For example, you can send emails from company@ or info@. It is up to you!`,
          },
          step5: {
            title: 'Set default sender',
            content: `Default sender is an email address used in your emails as a 'From' address. We strongly recommend setting a default sender to help increase your deliverability performance.`,
          },
          step6: {
            title: 'Success!',
            content: `Great! Your domain is now verified, and the default sender is set.`,
          },
        },
      },
    },
    template: {
      messages: {
        update_success: 'Update template successfully',
        create_success: 'Create template successfully',
      },
    },
    contact: {
      tabs: {
        overview: 'Overview',
        groups: 'Groups',
        all_contacts: 'All contacts',
      },
      form: {
        create_group: 'Create group',
        group_name: 'Group name',
        group: 'Group',
        last_name: 'Last name',
        first_name: 'First name',
        step1: 'Add contact details',
        step2: 'Choose contact groups',
        each_one: 'Add each contact',
        import: 'Import list contact. Download example CSV file here',
        csv: {
          heading: 'Select CSV file',
          title: 'Contains 3 columns (last_name, first_name, email)',
          example: 'Example file',
          start: 'Start',
        },
        errors: {
          required: 'This is required field',
          select_file: 'Please select a file',
          select_group: 'Please select a contact group need append',
        },
        messages: {
          group_success: 'Create contact group successfully',
          contact_success: 'Create contact successfully',
          import_success: 'Import contact list successfully',
        },
      },
      table: {
        head: {
          recipient: 'Recipient',
          status: 'Status',
          name: 'Name',
          email: 'Email',
        },
        body: {
          active: 'Active',
          subscribe: 'Subscribe',
          unsubscribe: 'Unsubscribe',
          deactive: 'Deactive',
          deleted: 'Deleted',
        },
      },
      messages: {
        remove_contact_success: 'Removed contact successfully',
        remove_group_success: 'Removed group successfully',
      },
      overview: {
        summary: 'Summary',
        all_contacts: 'All contacts',
        new_contacts: 'New contacts',
        subscribed: 'Subscribed',
        contact_today: 'New contacts today',
        unsubscribed: 'Unsubscribed',
        open_rate: 'Avg. open rate',
        click_rate: 'Avg. click rate',
        recent_growth: 'Recent growth',
        change: `Changes in audience in last {{range}} days`,
        performance: 'Audience performance',
        growth: 'Growth contact',
        form: 'Build relationships. Grow your list with our features and create forms or landing pages to collect new contacts',
        data: 'Contact in day',
        dropdown: 'Last {{range}} days',
      },
    },
    campaign: {
      table: {
        head: {
          name: 'Name',
          delivered: 'Delivered',
          opened: 'Opened',
          clicked: 'Clicked',
        },
      },
      form: {
        send_to: 'Send to',
        summary: 'Summary',
        summary_content_b2c:
          'Your campaign will be sent to <span style="font-weight:bold;font-size:18px">{{recipients}}</span> recipients. The amount paid for the campaign is <span style="font-weight:bold;font-size:18px">{{money}}</span>',
        summary_content_b2b:
          'Your campaign will be sent to <span style="font-weight:bold;font-size:18px">{{recipients}}</span> recipients.',
        subject_content: 'Subject and content',
        fromEmail: 'From email',
        content: 'Content',
        template: 'Choose from templates saved on your account',
        setting: 'Settings and tracking',
        tracking: 'Tracking',
        campaignName: 'Campaign name',
        tracks: {
          open: {
            title: 'Find out exactly how many times a recipient opened a communication you sent.',
            content: 'Track opens',
          },
          click: {
            title: 'Track clicks',
            content:
              'Find out if the recipient is interested enough not only to open your campaign but also to engage with them.',
          },
          stats: {
            title: 'Get campaign statistics into your email inbox',
            content:
              'You will receive an email with overall campaign statistics such as open rate, click rate and bounce rate within 48 hours of sending.',
          },
        },
        errors: {
          required: 'This is a required field',
          space: 'Does not include spaces',
          group: 'Please select group need send',
          format: 'Invalid format',
          template: 'Please select template',
        },
      },
      messages: {
        update_success: 'Updated campaign successfully',
        create_success: 'Created campaign successfully',
      },
      schedule: {
        schedule_campaign: 'Schedule campaign',
        time_at: 'At what time?',
        times: 'How many times?',
        future_datetime: 'Please select future time',
        select_datetime: 'Please select date time',
        send_time: 'Send one time',
      },
    },
  },
  translate_catalog: {
    city: ['City', 'Province'],
    district: ['City', 'Ward', 'District', 'Town', 'District'],
    ward: ['Town', 'Commune', 'Ward'],
    street: ['Street', 'Street', 'Provincial Highway', 'Street', 'Highway', 'Avenue'],
    demands: ['Sell', 'Lease', 'Buy', 'Rent', 'Assign'],
    industries: {
      real_estate: 'Real estate',
      spa_beauty: 'Spa/Beauty',
      cosmetics: 'Cosmetics',
      mother_and_baby: 'Mother and Baby',
      electronics_technology: 'Electronics/Technology',
      interior_exterior_furniture: 'Interior/Exterior',
      travel: 'Travel',
      cars_motorcycles: 'Cars/Motorcycles',
      food_beverages: 'Food/Food/Drinks',
      fashion: 'Fashion',
      home_appliances: 'Home appliances',
      education: 'Education',
      pets: 'Pets',
      jobs_recruitment: 'Jobs/Recruitment',
      finance_banking: 'Finance/Banking',
    },
    genders: {
      male: 'Male',
      female: 'Female',
      neutral: 'Neutral',
      non_disclosed: 'Not mentioned',
      all: 'All',
    },
    categories: {
      apartment: {
        title: 'Apartment',
        apartment: 'Apartment',
        duplex: 'Duplex',
        penthouse: 'Penthouse',
        serviced_apartment: ' Serviced apartment, mini',
        housing_project: 'Group, dormitory',
        officetel: 'Officetel',
      },
      house: {
        title: 'House',
        town_house: 'Street house, facade',
        alley_house: 'Lane house, alley',
        villa: 'Villa house',
        terrace_house: 'Townhouse',
      },
      land: {
        title: 'Land',
        industrial_land: 'Industrial land',
        agricultural_land: 'Agricultural land',
        residential_land: 'Residential land',
        ground_project: 'Project land',
      },
      commercial: {
        title: 'Office, Business premises',
        business_premises: 'Business premises',
        office: 'Office',
        shophouse: 'Shophouse',
        officetel: 'Officetel',
      },
      rooming_house: {
        title: 'Inn room',
        rooming_house: 'Inn room',
      },
    },
  },
  tool: {
    posted: 'Posted',
    collection: 'Collection',
    search: 'Search',
    profile: 'Personal',
    page: 'Page',
    lifetime: 'Lifetime',
    purchase: 'Purchase',
    post_success: 'Successfully posted with content',
    post_failed: 'Failed to post with content',
    target_not_found: {
      group: 'Group not found',
      friend: 'Friend not found',
    },
    remove_pending: 'Delete post pending approval:',
    skip_group_have_more_than: `Skip the group that is on {{input}} post “Waiting for Approval”`,
    content: 'Post',
    comment: 'Comment',
    estimated_time: '{{posts}} post in {{time}}',
    post_content: 'Post contains {{images}} images, {{video}} videos',
    post_link: 'Shared link: {{link}}',
    post_unkown: 'Unknown',
    upload: {
      title: 'Image/Video',
      first_content: '- Drag and drop or click to select photos/videos',
      second_content: '- Maximum photo/video capacity 500mb',
    },
    time: {
      days: 'day',
      hours: 'hour',
      minutes: 'minute',
      seconds: 'second',
    },
    status: {
      success: 'Success',
      pending: 'Pending',
      error: 'Error',
    },
    ai: {
      rewrite: 'AI rewrite',
      hashtag: 'Smart Hashtag',
    },
    form: {
      fields: {
        delay: {
          label: 'Delay',
          from: 'From (seconds)',
          to: 'To (seconds)',
        },
        up_times: 'Up times',
        assets: 'Images',
        sequence: {
          label: 'Sequence',
          description: 'Post sequentially (post 1->10 to each group)',
        },
        rotate: {
          label: 'Parallel',
          description: `Post parallelly (post 1 to group 1, post 2 to group 2)`,
        },
      },
      errors: {
        delay: {
          min: 'Minimum value is {{number}}',
          max: 'Maximum value is {{number}}',
        },
      },
    },
    btn: {
      post: 'Start',
      save: 'Save',
      view: 'View post',
      install: 'Install chrome extension',
      stop: 'Stop',
      pending: 'Pending',
      success: 'Success',
      more: 'View more',
      export: 'Export file',
      start: 'Start',
      canceling: 'Canceling',
      skip: 'Skip',
      guide: 'Guide',
      confirm: 'Confirm',
      cancel: 'Cancel',
      retry: 'Try again in {{time}}',
      continue: 'Continue',
      remain: 'Starts in {{time}}',
      slowdown: 'Slowdown...',
      payment: 'Payment',
      receive_account: 'Receive account $',
      withdraw: 'Withdraw $',
      edit: 'Edit',
      prev: 'Back',
      next: 'Next',
    },
    affiliate: {
      statuses: {
        draft: 'Draft',
        pending: 'Pending',
        accepted: 'Approved',
        rejected: 'Rejected',
        unpaid: 'Unpaid',
        paid: 'Received',
      },
      chart: {
        earn: 'Income',
        package: 'Package',
      },
      table: {
        date: 'Date',
        name: 'User',
        online: 'Online',
        payment_value: 'Unit price',
        package: 'Package',
        discount: 'Discount %',
        payment: 'Payment',
        status: 'Status',
        commission: 'Commission',
      },
      commission: {
        title: 'Update commission receipt information',
        front: 'Front',
        back: 'Back',
        cic: 'Citizen identification card',
        bankInfo: 'Bank information',
        bank: 'Bank',
        bankNumber: 'Account number',
        owner: 'Account name',
        email: 'Email received results',
        form: {
          error: {
            required: 'This is a required field',
            invalid_format: 'Invalid format',
            invalid_format_alphabet: 'Contains only capital letters without accents',
            maximum: 'Maximum {{amount}}',
            minimum: 'Minimum {{amount}}',
            refcode_length: 'Maximum {{amount}} characters',
            refcode_format: 'All letters and numbers do not contain special characters or spaces',
          },
        },
      },
      online: {
        recent: 'Recent',
        yesterday: 'Yesterday',
        specific_day: '{{day}} days ago',
        previous_month: 'Last month',
        specific_month: '{{month}} last month',
      },
      package: 'Package',
      previous_month: 'vs previous month',
      current_month: 'This month',
      next_month: 'Next month(expected)',
      bait: 'System WITHDRAWABLE this month',
      withdraw: 'Withdrawable',
      total: 'Total income',
      refcode: 'Referral code (Ref code)',
      monthly_package: 'monthly package',
      annual_package: 'annual package',
      contract: 'Detailed terms',
      list_commission: 'List commissions',
      status: 'Status',
    },
    export: {
      remove_post: 'Delete post',
      group_uid: 'Group UID',
      group_name: 'Group name',
      removed: 'Deleted',
      remains: 'Remains',
      type: 'Type',
      success: 'Success',
      fail: 'Fail',
      post: 'Post',
      post_uid: 'post UID',
      status: 'Status',
      comment: 'Comment',
      pending: 'Waiting for approval',
      actor: 'Poster',
      actor_uid: 'Poster UID',
      categorize: 'Categorize',
      match_product: 'Assemble product',
      phone_number: 'Phone number',
      email: 'Email',
      location: 'Address',
      gender: 'Gender',
      inbox: 'Messaging',
      add_friend: 'Add friend',
      like: 'Like',
      poke: 'Tickling',
      name: 'Name',
      remove_friend: 'Unfriend',
    },
    popup: {
      internet: {
        title: 'Warning',
        content: `Your Facebook card is in the form web.facebook or m.facebook, the software cannot work. Please use the wifi emitted from the Modem (not emitted from 3G or 4G devices)`,
      },
      warning: {
        title: 'Note',
        sub_title: 'You see this popup because tool COULD NOT POST to 5 contents or 5 groups consecutively',
        treatment: 'Reason:',
        items: [
          {
            name: 'Group error',
            description:
              'Group deactive, Not approved to join, Admin prohibits you from posting, Limits posts pending approval...',
            solution: 'Leave these groups and use the tool normally again.',
          },
          {
            name: 'Spam',
            description: `Try posting manually to a group, if a warning appears, it means you're stuck.`,
            solution: 'Let this account rest until you can post manually, you can use another account while waiting.',
            prevent: `How to limit posting spam: Default interval time (or longer), Post multiple types of content in parallel or one after another, Post with comments, Use AI Spin`,
          },
        ],
      },
      alert_action: {
        title: 'Slow down',
        sub_title: 'You posted too much in a short period of time!',
      },
      payment: {
        title: 'A tool that help you post & comment to ',
        sub: 'Multiple Facebook Groups',
        release: 'LAUNCHING IN...',
        time: {
          days: 'Days',
          hours: 'Hours',
          minutes: 'Minutes',
          seconds: 'Seconds',
        },
        access: {
          main_content: 'Get Early Access',
          secondary_content: '<span style="font-weight: bold; font-size:1.25rem; color:{{color}}">1 Month FREE</span>',
          subtitle: 'then $ 2,5 USD/month',
        },
        deal: {
          main_content: 'LIFE-TIME DEAL',
          secondary_content: '$ 25 USD',
          subtitle: 'Unlimited time usage',
        },
      },
      collection: {
        title: 'Collection name',
      },
      openai: {
        title: 'Update OpenAI API Key',
      },
      early_access: {
        title: 'Thank You !',
        release: 'You just got <span style="font-weight: bold; color:{{color}}">1 Month FREE</span> begin in...',
        purchase: 'PURCHASE THIS OFFER BEFORE IT’S GONE',
        time: {
          days: 'Days',
          hours: 'Hours',
          minutes: 'Minutes',
          seconds: 'Seconds',
        },
        deal: {
          main_content: 'LIFE-TIME DEAL',
          secondary_content: '$ 25 USD',
          subtitle: 'Unlimited time usage',
        },
      },
      extension: {
        title: 'You must install the extension',
        content:
          'To use the tools, you first need to install our chrome extension. We apologize for this inconvenience.',
      },
      pricing: {
        title: 'A tool that help you post & comment to multiple ',
        sub: 'Facebook Groups',
        heading: 'PRICING',
        features: [
          'No login required 🔒',
          'Profile and Page can be used',
          'Reduce Spam and CP',
          'Free AI spin',
          'Favorite collections',
        ],
        tier: 'Tier',
        tiers: [
          {
            tier: 1,
            name: 'BASIC',
            cost: {
              month: 100000,
              year: 50000,
            },
            features: [
              {
                text: 'Post (2 modes)',
                info: 'Post 1 post to multiple groups / Post multiple posts to multiple groups.',
              },
              {
                text: 'AI spin',
                info: 'Every time you post, the tool automatically uses AI to rewrite the content to avoid Spam.',
              },
              {
                text: 'Ne Checkpoint',
                info: '(Hidden feature) Tool automatically detects and avoids Checkpoints.',
              },
              {
                text: 'Spam detected',
                info: '(Hidden feature) Tool automatically detects Spam and stops account protection.',
              },
              {
                text: 'Post slowly to avoid Spam',
                info: '(Hidden feature) Tool automatically detects and posts slowly to avoid Spam.',
              },
              {
                text: 'Random posting time to avoid Spam',
                info: 'Tool chooses random time between 2 postings to avoid Spam.',
              },
              {
                text: 'Save favorite group, posted post',
                info: 'You can select and save your favorite groups and posts and quickly select them next time.',
              },
              {
                text: 'Used with multiple Facebook accounts',
                info: 'One tool account can be used for as many Facebook accounts as possible.',
              },
              {
                text: 'For use with Pages and Profiles',
                info: 'Tool can be used with Pages and Profiles.',
              },
            ],
          },
          {
            tier: 2,
            name: 'PLUS',
            cost: {
              month: 200000,
              year: 100000,
            },
            features: [
              {
                text: 'All features of the BASIC package, and:',
                info: 'The PLUS package will have all the features of the BASIC package.',
              },
              {
                text: 'Post (4 modes)',
                info: 'Post with comments immediately after / Post at the same time to avoid Spam.',
              },
              {
                text: 'Post with background',
                info: 'Posting with a random background increases engagement.',
              },
              {
                text: 'Post video/clip',
                info: 'Post video clips under <500 Mb.',
              },
              {
                text: 'Delete post pending approval',
                info: 'Delete pending posts (pending, admin not approved) in groups.',
              },
              {
                text: 'Walking comment',
                info: 'Comment on the latest posts in groups.',
              },
              {
                text: 'Share link',
                info: 'Post to share links in groups.',
              },
              {
                text: 'Suppress posted post',
                info: 'Repost posts in groups.',
              },
              {
                text: 'Export report',
                info: 'Export report with complete information.',
              },
            ],
          },
          {
            tier: 3,
            name: 'PRO',
            cost: {
              month: 400000,
              year: 200000,
            },
            features: [
              {
                text: 'All PLUS plan features, and:',
                info: 'The PRO package will have all the features of the PLUS package.',
              },
              {
                text: 'Personal page for texting, commenting, liking, filtering interactions, poking, unfriending friends list',
                info: 'Tool automatically likes, comments, ticks, messages, or unfriends, using AI spin to avoid Spam; filter friend interactions over time.',
              },
              {
                text: 'Personal page automatically sends friends with UID list',
                info: 'Send friends in bulk by UID.',
              },
              {
                text: 'Message page for customers who have already inboxed',
                info: 'Tool automatically messages customers who have already inboxed, using AI spin to avoid Spam',
              },
              {
                text: 'Page likes, comments, filters interactions that customers have followed',
                info: 'Tool automatically likes, comments, sends messages, uses AI spin to avoid Spam; filter follower interactions over time.',
              },
              {
                text: 'Post news with tag (highlight, all)',
                info: 'Increase interaction and reach of news',
              },
            ],
          },
          {
            tier: 4,
            name: 'MAX',
            cost: {
              month: 1000000,
              year: 500000,
            },
            features: [
              {
                text: 'All PRO features, and:',
                info: 'MAX package will have all the features of PRO package.',
              },
              {
                text: 'Automatically search for customers (all or in specified groups)',
                info: 'Based on the keywords you enter, the tool will find posts with keywords on all of Facebook or only search in the groups you have selected.',
              },
              {
                text: 'Automatically find customer information',
                info: `Tool automatically finds out the customer's Phone Number, Email, Location, Date of Birth (if any).`,
              },
              {
                text: 'AI automatically classifies customers',
                info: 'Tool uses AI to filter and classify customer types.',
              },
              {
                text: 'AI automatically assembles suitable products',
                info: 'Tool uses AI to read your post/comment content, products and automatically send products that best suit customer needs.',
              },
              {
                text: 'AI automatically comments, sends personalized messages, automatically makes friends with customers',
                info: 'Tool uses AI to automatically comment, send inbox messages, and make friends with customers.',
              },
            ],
          },
        ],
        monthly: 'monthly',
        semi_annual: 'semi annual',
        annual: 'annual',
        month_value: '$5',
        annual_value: '$2.5',
        discount: 'Save upto {{percent}}%',
        bill_year: 'Billed yearly',
        bill_annual: 'Yearly',
        bill_monthly: 'Monthly',
      },
      spin: {
        title: 'Warning',
        content: `<p>You should use “<a href="{{link}}" target="_blank">AI rewrite</a>” when posting on {{amount}} groups to limit spam</p>`,
      },
      confirm_job: {
        title: 'Note',
        content: `There was an error in saving the campaign, will it affect the up post process, still continue?`,
      },
    },
    lead: {
      stat: 'Stat',
      keyword: 'Search keyword',
      product_label: 'Product or Service',
      product_placeholder:
        'Product or Service description, targeting audience, your contact info so AI can analyze and classify content/user correctly',
      categorize: {
        title: 'Classification',
        seller: 'Seller',
        buyer: 'Buyer',
        unknown: 'Unknown',
      },
      action: {
        title: 'Action',
        comment: {
          title: 'Comment',
          level_1: 'AI responds to customers',
          level_2: 'Keep product content intact',
        },
        add_friend: 'Add friend',
        inbox: 'Inbox (Beta)',
      },
      chart: {
        all: 'All',
        categorize: 'Categorize',
        total_post: 'Total posts',
        product: 'Assemble product',
        comment: 'Comment',
        add_friend: 'Add friend',
        inbox: 'Inbox',
      },
      table: {
        head: {
          post: 'Post',
          name: 'Username',
          uid: 'UID',
          comment: 'Comment',
          product: 'Assemble product',
          add_friend: 'Add friend',
          phone: 'Phone',
          email: 'Email',
          location: 'Address',
          gender: 'Gender',
          type: 'Type',
          inbox: 'Inbox',
          categorize: 'Categorize',
        },
      },
      form: {
        errors: {
          max_content: 'Maximum number of characters is {{max}}',
        },
      },
    },
    interact: {
      actions: {
        poke: 'Poke',
        like: 'Like',
        comment: 'Comment',
        inbox: 'Inbox',
        remove_friend: 'Remove friend',
        add_friend: 'Add friend',
      },
      form: {
        filter: 'Filter interactions',
        from: 'From',
        to: 'To',
        post_amount: 'Post amount',
        min: `Smallest end date from {{date}}`,
        add_friend_content: 'List UID',
      },
      table: {
        name: `Friend's name`,
        comment: 'Comment',
        reaction: 'Reaction',
        messages: 'Messages',
      },
    },
    up_post: {
      campaign: 'Campaign posted',
    },
    messages: {
      amount_pending_posts: 'There are more than {{amount}} pending posts in the group',
      exceeded_pending_posts: 'The number of pending posts in the group is more than your setting',
      participate_approval: 'Waiting for approval to join the group',
      check_group: 'Group has problem: {{warning_message}}',
      error_group: 'Group has problem',
      redirect_confirm: 'Redirect confirmation',
      bought_lifetime: 'You have purchased life-time package',
      select_content: 'Please highlight the content that needs editing with bold text',
      empty_content: 'Content is empty',
      empty_post_content: 'Select photos and content to post',
      select_group: 'Please select a group to post',
      select_friend: 'Please friend a group to interact',
      invalid_uid: 'Invalid UID',
      select_action: 'Please select action',
      delay_time: 'Please review the delay time',
      maximum_words: '{{Text}} must not exceed {{maximum}} words',
      maximum_part: 'Maximum {{maximum}} {{text}} per tab',
      newline_spin: `Do not contain newline in {{text}}`,
      maximum_image: 'Maximum {{maximum}} images',
      spin_error: 'An error occurred while using "AI Rewrite" content. Please check!',
      spin_tab_error: `An error occurred while “AI Rewriting” content in tab {{tabs}}. Click confirm to continue reposting content in the remaining tabs!`,
      required_keyword: 'Keyword required',
      required_tier3: 'Feature for users who have purchased the MAX package',
      required_action: 'Please select at least 1 action',
      required_categorize: 'Please select at least 1 customer category',
      categorize_failed: 'An error occurred while classifying customers',
      support_max: 'Feature for users who have purchased the MAX package',
      not_found_lead: 'No related posts found',
      limit_inbox: 'The message limit ({{amount}} messages/day) has been reached',
      limit_read_facebook_info:
        'The limit for the number of information retrieval has been reached ({{amount}} people/day)',
      limit_crawl: 'Crawl limit reached ({{amount}} posts/day)',
      select_comments: 'Select at least 1 comment',
      select_campaign: 'Select at least 1 campaign',
      update_bank_success: 'Updated information successfully',
      update_bank_fail: 'Update information failed',
      sent_withdraw_request: 'Request sent',
      failed_send_withdraw_request: 'An error occurred while sending the request',
      dont_enough_commission_withdraw: 'Minimum commission {{amount}} is required to submit a request',
    },
    tooltip: {
      ai_rewrite: `Select max {{maximum_parts}} text strings. Each string doesn't exceed {{maximum_words}} words. Do not include new line.`,
      banner: 'Banner (only allows 130 characters)',
      mention: 'Automatically mention everyone/ highlight',
      shuffle: 'Automatically shuffle photo/video order',
      sale_format: 'Automatically turn off sales format posts',
      pending_post: 'Amount pending posts',
      pending_post_removed: 'Deleted',
    },
    footer: {
      is: 'is an online business support tool on Facebook that uses AI to help individuals and companies find potential customers most effectively and save time.',
      navs: [
        {
          title: '',
          items: [
            { text: 'Earn', link: '#', disable: true, icon: 'tabler:currency-dollar' },
            { text: 'Manual', link: 'https://doc.fbtool.net', icon: 'streamline:manual-book' },
            { text: 'FAQ', link: 'https://doc.fbtool.net/cau-hoi-thuong-gap', icon: 'mdi:faq' },
            { text: 'Support', link: 'https://zalo.me/4152692317049608912', icon: 'bx:support' },
          ],
        },
      ],
    },
  },
  activity: {
    audience: 'Audience',
    click: 'Click',
    comment: 'Comment',
    group_comment: 'Group comment',
    group_post: 'Group post',
    group_reaction: 'Group reaction',
    group_view: 'Group views',
    impression: 'Impression',
    member_count: 'Member',
    reaction: 'Reaction',
    sentiment_negative: 'Negative',
    sentiment_neutral: 'Neutral',
    sentiment_positive: 'Positive',
    share: 'Share',
    ads_content: 'Advertisement',
    seo_content: 'SEO posts',
    seeding_content: 'Seeding posts',
  },
  today: 'Today',
  empty_data: 'Empty',
  version: 'build',
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  banks: [
    {
      name: 'Vietnam Bank for Agriculture and Rural Development',
      brandName: 'Agribank',
    },
    {
      name: 'Global Petroleum Bank Limited',
      brandName: 'OceanBank',
    },
    {
      name: 'Ocean Bank Limited',
      brandName: 'GPBank',
    },
    {
      name: 'Construction Bank Limited',
      brandName: 'CB',
    },
    {
      name: 'Vietnam Joint Stock Commercial Bank for Industry and Trade',
      brandName: 'VietinBank',
    },
    {
      name: 'Vietnam Joint Stock Commercial Bank for Investment and Development',
      brandName: 'BIDV',
    },
    {
      name: 'Vietnam Joint Stock Commercial Bank for Foreign Trade',
      brandName: 'Vietcombank',
    },
    {
      name: 'Asia Commercial Joint Stock Bank',
      brandName: 'ACB',
    },
    {
      name: 'An Binh Commercial Joint Stock Bank',
      brandName: 'ABBANK',
    },
    {
      name: 'Ban Viet Commercial Joint Stock Bank',
      brandName: 'Viet Capital Bank',
    },
    {
      name: 'Bao Viet Commercial Joint Stock Bank',
      brandName: 'BAOVIET Bank',
    },
    {
      name: 'Bac A Commercial Joint Stock Bank',
      brandName: 'Bac A Bank',
    },
    {
      name: 'Lien Viet Post Commercial Joint Stock Bank',
      brandName: 'LienVietPostBank',
    },
    {
      name: 'Vietnam Joint Stock Commercial Bank for the People',
      brandName: 'PVcomBank',
    },
    {
      name: 'Dong A Commercial Joint Stock Bank',
      brandName: 'DongA Bank',
    },
    {
      name: 'Dong A Commercial Joint Stock Bank Nam A',
      brandName: 'SeABank',
    },
    {
      name: 'Maritime Commercial Joint Stock Bank',
      brandName: 'MSB',
    },
    {
      name: 'Kien Long Commercial Joint Stock Bank',
      brandName: 'Kienlongbank',
    },
    {
      name: 'Technological and Commercial Joint Stock Bank',
      brandName: 'Techcombank',
    },
    {
      name: 'Nam A Commercial Joint Stock Bank',
      brandName: 'Nam A Bank',
    },
    {
      name: 'Oriental Commercial Joint Stock Bank',
      brandName: 'OCB',
    },
    {
      name: 'Military Commercial Joint Stock Bank',
      brandName: 'MB',
    },
    {
      name: 'International Commercial Joint Stock Bank',
      brandName: 'VIB',
    },
    {
      name: 'National Commercial Joint Stock Bank',
      brandName: 'NCB',
    },
    {
      name: 'Saigon Joint Stock Commercial Bank',
      brandName: 'SCB',
    },
    {
      name: 'Saigon Industry and Trade Joint Stock Commercial Bank',
      brandName: 'SAIGONBANK',
    },
    {
      name: 'Saigon - Hanoi Commercial Joint Stock Bank',
      brandName: 'SHB',
    },
    {
      name: 'Saigon Thuong Tin Joint Stock Commercial Bank',
      brandName: 'Sacombank',
    },
    {
      name: 'Tien Phong Joint Stock Commercial Bank',
      brandName: 'TPBank',
    },
    {
      name: 'Viet A Joint Stock Commercial Bank',
      brandName: 'VietABank',
    },
    {
      name: 'Vietnam Prosperity Joint Stock Commercial Bank',
      brandName: 'VPBank',
    },
    {
      name: 'Vietnam Thuong Tin Joint Stock Commercial Bank',
      brandName: 'Vietbank',
    },
    {
      name: 'Petrolimex Joint Stock Commercial Bank',
      brandName: 'PG Bank',
    },
    {
      name: 'Export Import Commercial Joint Stock Bank',
      brandName: 'Eximbank',
    },
    {
      name: 'Ho Chi Minh City Development Joint Stock Commercial Bank',
      brandName: 'HDBank',
    },
    {
      name: 'Indovina Bank Limited',
      brandName: 'IVB',
    },
    {
      name: 'Vietnam-Russia Joint Venture Bank',
      brandName: 'VRB',
    },
    {
      name: 'ANZ Vietnam Bank Limited',
      brandName: 'ANZVL',
    },
    {
      name: 'Hong Leong Bank Vietnam Limited',
      brandName: 'HLBVN',
    },
    {
      name: 'HSBC Vietnam Bank Limited Nam',
      brandName: 'HSBC',
    },
    {
      name: 'Shinhan Bank Vietnam Limited',
      brandName: 'SHBVN',
    },
    {
      name: 'Standard Chartered Bank Vietnam Limited',
      brandName: 'SCBVL',
    },
    {
      name: 'Public Bank Vietnam Limited',
      brandName: 'PBVN',
    },
    {
      name: 'CIMB Vietnam Limited',
      brandName: 'CIMB',
    },
    {
      name: 'Woori Vietnam Limited',
      brandName: 'Woori',
    },
    {
      name: 'UOB Vietnam Limited',
      brandName: 'UOB',
    },
    {
      name: 'Vietnam Bank for Social Policies',
      brandName: 'VBSP',
    },
    {
      name: 'Vietnam Development Bank',
      brandName: 'VDB',
    },
    {
      name: 'Vietnam Cooperative Bank',
      brandName: 'Co-opBank',
    },
  ],
};

export default en;
