import NoSleep from '@zakj/no-sleep';
import { uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IS_LOCALHOST } from '../config';
import { useStatsGuard, useSync } from '../hooks/lead';
import useAuth from '../hooks/useAuth';
import useBoolean from '../hooks/useBoolean';
import useDraftForm from '../hooks/useDraftForm';
import useFBContext from '../hooks/useFBContext';

// hooks
import useLocales from '../hooks/useLocales';
import useRefCustom from '../hooks/useRefCustom';
import useToolContext from '../hooks/useToolContext';
import useToolFunction from '../hooks/useToolFunction';
import useWebSocket from '../hooks/useWS';
import axiosInstance from '../utils/axios';
import { convertFileToUint8Array } from '../utils/fileUtils';
import { isSameDateWith } from '../utils/formatTime';
import { delayFunc, parseJSON, randomInRange, removeEmoji } from '../utils/others';

// 15s for test
const DEFAULT_TIME_GAP = IS_LOCALHOST ? 1000 * 15 : 1000 * 60 * 15;

// Delay search posts from facebook
const DELAY_BETWEEN_SEARCH_POSTS = IS_LOCALHOST ? 1000 * 5 : 1000 * 20;

const MAX_CHARACTERS = 500;

const DEFAULT_LEAD_AMOUNT = IS_LOCALHOST ? 5 : 10;

const DELAY_TIME_RENDER_LEADS = 5000;

const DELAY_TIME_EXCEED_LIMIT = process.env.ENVIRONMENT === 'production' ? 5 * 60 * 1000 : 30 * 1000;

const FBTOOL_AI_CATEGORIZE_ENDPOINT = 'api/v2/tool/lead/categorize/';

const ACTIONS = {
  COMMENT: 'comment',
  INBOX: 'inbox',
  ADD_FRIEND: 'add_friend',
};

const STATS_NAME = {
  TOTAL_POST: 'total_post',
  CATEGORIZE: 'categorize',
  PRODUCT: 'product',
  COMMENT: 'comment',
  INBOX: 'inbox',
  ADD_FRIEND: 'add_friend',
  CRAWLED_LEADS: 'crawled',
  READ_FACEBOOK_INFO: 'facebook_info',
};

const STATS_STORAGE_NAME = 'lead-stats';

const CATEGORIES = {
  BUYER: 'buyer',
  SELLER: 'seller',
  UNKNOWN: 'unknown',
};

const DEFAULT_SETTINGS = {
  keyword: '',
  contents: [],
  groupIds: [],
  timeGap: DEFAULT_TIME_GAP,
  options: {
    categorize: [],
    actions: [],
  },
};

const initialState = {
  leadsResult: [],
  isRunning: false,
  isExecutingLeadsSeeding: false,
  leadStats: [],
  leadSetting: DEFAULT_SETTINGS,
  noSleep: {},
  onStartLeadListen: () => {},
  onCancel: () => {},
  onRemoveStats: () => {},
};

const LeadContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
LeadProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function LeadProvider({ children }) {
  const { user } = useAuth();

  const { addToStatsNeedSync } = useSync();

  const { increaseInboxSent, getCurrentLeadsCrawled, DAILY_LIMIT_CRAWL, increaseLeadsReadInfo } = useStatsGuard();

  const { keepAlive, wsClient, checkActiveOfWS } = useWebSocket();

  const noSleep = new NoSleep();

  const canUseTier4 = user?.fbtool?.tier >= 4;

  const { enqueueSnackbar } = useSnackbar();

  const { getPostsInGroupByKeyword, getCommentsFromFeedback, leadsSeeding, getSearchPosts, stopQueue } =
    useToolFunction();

  const { FBUser } = useFBContext();

  const { checkCommonData, isOpenWarningModal, ACTIONS_DELAY, updateTaskRunningStatus } = useToolContext();

  const { translate: tMessage } = useLocales('tool.messages');

  const { translate } = useLocales();

  // Stats for chart
  const initStats = parseJSON(localStorage.getItem(STATS_STORAGE_NAME), [])?.map((item) => ({
    ...item,
    at: new Date(item?.at),
  }));
  const [leadStats, setLeadStats] = useState(initStats);

  const storageRef = useRef();

  const isRunning = useBoolean();

  // Lead listener
  // SHOULD BE USE  USEREF TO MANAGE DATA WHEN WORK WITH COMPLEX LOGIC
  const [, setLeadsPending, leadsPendingRef] = useRefCustom([]);

  const [leadSetting, setLeadSetting, leadSettingRef] = useRefCustom(DEFAULT_SETTINGS);

  const [, setPagination, paginationRef] = useRefCustom({
    cursor: null,
    hasNextPage: false,
  });

  const [, setPaginationGroups, paginationGroupsRef] = useRefCustom({});

  const [leadsResult, setLeadsResult, leadsResultRef] = useRefCustom([]);

  const [, setCategorizePayload, categorizePayloadRef] = useRefCustom(null);

  const [, setExcudeReadFacebookInfo, excudeReadFacebookInfoRef] = useRefCustom([]);

  const taskRef = useRef();

  const gotExceedLimitAI = useRef(false);

  const [isExecutingLeadsSeeding, setIsExecutingLeadsSeeding, isExecutingLeadsSeedingRef] = useRefCustom(false);

  const [, setSearchTask, searchTaskRef] = useRefCustom(null);

  const [, setAllowRunTask, allowRunTaskRef] = useRefCustom(false);

  const isLoadedLeadResult = useRef(false);

  const { onFormDataChange: onLeadResultChange, getPreviousFormData } = useDraftForm('result');

  useEffect(() => {
    updateTaskRunningStatus(isRunning?.value);
  }, [isRunning?.value]);

  useEffect(() => {
    if (isOpenWarningModal) {
      onCancel();
    }
  }, [isOpenWarningModal]);

  useEffect(() => {
    const previousResult = getPreviousFormData('result');
    if (previousResult) {
      setLeadsResult(previousResult);
    }
    isLoadedLeadResult.current = true;
  }, []);

  useEffect(() => {
    // Storage on local
    if (isLoadedLeadResult?.current) {
      onLeadResultChange(leadsResult);
    }
  }, [leadsResult]);

  const getLeadsRemain = () => {
    return leadsResultRef?.current
      ?.filter((item) => !item?.product && !item?.comment && !item?.inbox && !item?.add_friend)
      ?.filter((item) => leadSettingRef?.current?.options?.categorize?.indexOf(item?.type) !== -1);
  };

  const isCrawlLeads = () => {
    try {
      const { actions } = leadSettingRef?.current?.options || {};
      return actions?.length === 0;
    } catch (error) {
      return false;
    }
  };

  const addToLimitCrawledLeads = (value = 0) => {
    // Detect limit crawl leads per day
    if (isCrawlLeads()) {
      addToStatsNeedSync(STATS_NAME.CRAWLED_LEADS, value);
    }
  };

  // Update stats data + update in localStorage
  const addToLeadStats = useCallback(
    (name, value = 0) => {
      if (value === 0 || !name) {
        return;
      }

      addToStatsNeedSync(name, value);

      if (name === STATS_NAME.INBOX) {
        increaseInboxSent();
      }

      setLeadStats((prev) => {
        const temp = [...prev];
        const index = temp?.findIndex(
          (item) => item?.name === name && isSameDateWith(new Date(), item?.at, null, null, 0)
        );
        if (index !== -1) {
          temp[index].value += value;
        } else {
          temp.push({ name, at: new Date(), value });
        }
        return temp;
      });
    },
    [leadStats]
  );

  useEffect(() => {
    // Storage stats in local
    if (storageRef?.current) {
      clearTimeout(storageRef?.current);
    }

    storageRef.current = setTimeout(() => {
      localStorage.setItem(STATS_STORAGE_NAME, JSON.stringify(leadStats));
      clearTimeout(storageRef?.current);
    }, 2000);
  }, [leadStats]);

  // Categorize receive
  useEffect(() => {
    if (wsClient) {
      const handleMessage = (message) => {
        console.log('Received categorize message', message);
        const { data } = message;
        onReceiveCategorizeResult(data);
      };

      const handleReceiveInformation = (message) => {
        console.log('Received lead information message', message);
        const { fbuid, data } = message;
        if (fbuid) {
          onReceiveLeadInformationResult(fbuid, data);
        }
      };

      wsClient?.on('fbtool-lead-information', handleReceiveInformation);
      wsClient?.on('fbtool-lead-categorize', handleMessage);

      return () => {
        wsClient?.off('fbtool-lead-information', handleReceiveInformation);
        wsClient?.off('fbtool-lead-categorize', handleMessage);
      };
    }
  }, [wsClient]);

  const onReceiveLeadInformationResult = (fbuid, data) => {
    try {
      const { phone1: phoneNumber1, phone2: phoneNumber2, location, email, gender } = data || {};
      const phoneNumber = [phoneNumber1, phoneNumber2]?.filter((phone) => phone)?.join(', ');

      const result = {
        phone: { success: !!phoneNumber, phoneNumber },
        location: { success: !!location, location },
        email: { success: !!email, email },
        gender: { success: !!gender, gender },
        // Marked as check facebook profile
        info: { success: true },
      };

      // Bulk update
      const newArray = [...leadsResultRef?.current]?.map((item) => {
        const actorId = item?.actor?.id;
        if (actorId && actorId === fbuid) {
          return { ...item, ...(result || {}) };
        }
        return item;
      });
      setLeadsResult([...newArray]);
      storeActionResult(result);
    } catch (error) {
      console.log(error);
    }
  };

  // Get leads data
  const fetchListData = async (keyword = null) => {
    try {
      const currentKeyword = keyword || leadSettingRef?.current?.keyword;

      // Check get data from groups or search engine of facebook ?
      let groupsSize = leadSettingRef?.current?.groupIds?.length;
      // SEARCH IN GROUP
      if (groupsSize !== 0) {
        let groupIds = leadSettingRef?.current?.groupIds;
        /**
         * We will prioritize for group have data. Some groups will not have posts related to keyword
         */
        const filteredGroups = Object.keys(paginationGroupsRef?.current)?.filter(
          (groupId) =>
            paginationGroupsRef?.current[groupId]?.hasNextPage && paginationGroupsRef?.current[groupId]?.cursor
        );
        if (filteredGroups?.length !== 0) {
          groupsSize = filteredGroups?.length;
          groupIds = filteredGroups;
        }

        // Random group get data
        const radIndex = randomInRange({ from: 0, to: groupsSize - 1 });
        const groupId = groupIds[radIndex];
        // Get current pagination and fetch
        const paginationGroups = paginationGroupsRef?.current;
        const paginationInfo = paginationGroups[groupId];
        let cursor = null;
        if (paginationInfo && paginationInfo?.hasNextPage && paginationInfo?.cursor) {
          cursor = paginationInfo?.cursor;
        }
        const res = (await getPostsInGroupByKeyword(groupId, currentKeyword, cursor)) || {};
        res.groupId = groupId;
        return res;
      }

      // SEARCH IN SEARCH ENGINE
      // Prepare payload
      const pagination = paginationRef?.current;
      const payload = { options: { recentPost: true }, keyword: currentKeyword };
      if (pagination?.hasNextPage && pagination?.cursor) {
        payload.cursor = pagination?.cursor;
      }

      return getSearchPosts(payload);
    } catch (error) {
      console.log('fetchListData', error);
      return null;
    }
  };

  const onSetPagination = (payload) => {
    const { groupId } = payload || {};
    const { end_cursor: cursor, has_next_page: hasNextPage } = payload?.pagination || {};

    // Set into hash map of groups pagination
    if (groupId) {
      const currentData = paginationGroupsRef?.current;
      currentData[groupId] = {
        cursor: cursor || null,
        hasNextPage: hasNextPage || false,
      };
      setPaginationGroups(currentData);
      return;
    }

    // Set into search engine pagination of facebook
    setPagination({
      cursor: cursor || null,
      hasNextPage: hasNextPage || false,
    });
  };

  const getEmptyLeadsPendingPayload = () => {
    const groupsSize = leadSettingRef?.current?.groupIds?.length;
    // Keep previous pagination
    if (groupsSize !== 0) {
      const radIndex = randomInRange({ from: 0, to: groupsSize - 1 });
      const groupId = leadSettingRef?.current?.groupIds[radIndex];
      // Get current pagination and fetch
      const paginationGroups = paginationGroupsRef?.current;
      const paginationInfo = paginationGroups[groupId];
      const { hasNextPage, cursor } = paginationInfo;
      return { groupId, posts: [], pagination: { end_cursor: cursor, has_next_page: hasNextPage } };
    }

    const { cursor, hasNextPage } = paginationRef?.current || {};
    return {
      posts: [],
      pagination: { end_cursor: cursor, has_next_page: hasNextPage },
    };
  };

  const runPrepareData = async (keyword = null) => {
    try {
      // Detect limit AI requests
      if (gotExceedLimitAI?.current) {
        console.log(
          new Date(),
          `Got exceeded AI limit. Continue after delay ${DELAY_TIME_EXCEED_LIMIT / 1000} seconds`
        );
        await delayFunc(DELAY_TIME_EXCEED_LIMIT);
        receiveLeadsPendingCallback(getEmptyLeadsPendingPayload());
        return;
      }

      // Check remain leads haven't been run yet. Run without featch new leads fom facebook
      const leadsRemain = getLeadsRemain();
      if (leadsRemain?.length >= DEFAULT_LEAD_AMOUNT) {
        receiveLeadsPendingCallback(getEmptyLeadsPendingPayload());
        return;
      }

      const res = await fetchListData(keyword);
      if (!res && !paginationRef?.current?.hasNextPage && !paginationRef?.current?.cursor) {
        // enqueueSnackbar(tMessage('not_found_lead'), { variant: 'error' });
        // onCancel();
        // return;
        await delayFunc(DELAY_BETWEEN_SEARCH_POSTS * 2);
      }
      receiveLeadsPendingCallback(res);
    } catch (error) {
      console.log('runPrepareData', error);
    }
  };

  const getUniqueId = (lead) => {
    const content = lead?.content?.message?.text;
    const splited = content ? `${content?.slice(0, 50)}` : 'empty';
    return `${lead?.actor?.id}-${splited}`;
  };

  const formatLeadsPending = useCallback(
    (posts, source = 'post') => {
      const formatedPosts = uniqBy(
        posts?.map((item) => ({ ...item, source, uniqueId: getUniqueId(item) })),
        'uniqueId'
      );

      const previousData = [...leadsPendingRef?.current, ...leadsResultRef?.current];
      const filterKeys = [
        { key: 'feedbackIds', path: ['feedback', 'id'] },
        { key: 'uniqueIds', path: ['uniqueId'] },
      ]?.reduce(
        (res, filterOption) => {
          res[filterOption.key] = previousData?.map((item) =>
            filterOption?.path.reduce((value, property) => value[property], item)
          );

          return res;
        },
        { feedbackIds: [], uniqueIds: [] }
      );

      return formatedPosts?.filter(
        (item) =>
          item?.actor?.id !== FBUser?.uid &&
          filterKeys?.feedbackIds?.indexOf(item?.feedback?.id) === -1 &&
          filterKeys?.uniqueIds?.indexOf(item?.uniqueId) === -1
      );
    },
    [FBUser, leadsResult]
  );

  const receiveLeadsPendingCallback = useCallback(
    async (payload) => {
      const { posts } = payload || {};

      onSetPagination(payload);

      const filteredData = [];

      const data = formatLeadsPending(posts);

      // Fetch comments
      for (const post of data) {
        const otherLeads = await getLeadsInComments([post], true);
        filteredData.push(...[post, ...otherLeads]);
      }

      // Store stats
      addToLeadStats(STATS_NAME.TOTAL_POST, filteredData?.length);

      setLeadsPending([...leadsPendingRef?.current, ...filteredData]);
      console.log('Received lead posts');

      onChangeLeadsPending();
    },
    [addToLeadStats, FBUser]
  );

  const storeActionResult = useCallback(
    (result) => {
      try {
        const mapValue = {
          comment: STATS_NAME.COMMENT,
          inbox: STATS_NAME.INBOX,
          add_friend: STATS_NAME.ADD_FRIEND,
          product: STATS_NAME.PRODUCT,
        };

        // Store stats
        Object.keys(result || {}).forEach((key) => {
          if (result[key]?.success && mapValue[key]) {
            addToLeadStats(mapValue[key], 1);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    [addToLeadStats]
  );

  const receiveLeadsSeedingCallback = (payload) => {
    const { feedback, result, type } = payload || {};

    if (type && type === STATS_NAME.READ_FACEBOOK_INFO) {
      addToLeadStats(STATS_NAME.READ_FACEBOOK_INFO, 1);
      increaseLeadsReadInfo();
      if (payload?.uid) {
        setExcudeReadFacebookInfo([...excudeReadFacebookInfoRef?.current, payload?.uid]);
      }
      return;
    }

    const newArray = [...leadsResultRef?.current];
    const index = newArray.findIndex((item) => item?.feedback?.id === feedback);
    if (index !== -1) {
      newArray[index] = { ...newArray[index], ...(result || {}) };
    }
    setLeadsResult([...newArray]);
    storeActionResult(result);
  };

  const categorizeContents = async (contents, products) => {
    try {
      const { data } = await axiosInstance.post(FBTOOL_AI_CATEGORIZE_ENDPOINT, {
        data: contents,
        keyword: leadSettingRef?.current?.keyword,
        products,
      });
      return { success: !!data, data };
    } catch (error) {
      console.log(error);
      const permissionMessage = error?.detail;
      const LIMIT_TEXT = ['giới hạn', 'limit'];
      const ABS_TEXT = 'AI';
      const isLimitAIRequest =
        permissionMessage &&
        LIMIT_TEXT.some(
          (item) => permissionMessage?.indexOf(item) !== -1 && permissionMessage?.indexOf(ABS_TEXT) !== -1
        );

      enqueueSnackbar(permissionMessage || translate('server_error'), { variant: 'error', persist: true });
      return { success: false, shouldStop: isLimitAIRequest };
    }
  };

  const getCommentsCategorize = (feedbackId) => {
    const leadsInComments = leadsPendingRef?.current?.filter((item) => item?.parent?.id === feedbackId);
    return {
      leadsInComments,
      comments: leadsInComments?.map((item) => ({
        uid: item?.feedback?.id,
        content: item?.content?.message?.text,
      })),
    };
  };

  const categoryLeads = useCallback(
    async (leads = []) => {
      try {
        // Reset
        gotExceedLimitAI.current = false;

        // Main leads need categorize
        let leadsCategorized = [...leads];
        const feedbackIdsWattingForCategorize = [];
        const subLeads = [];

        // Mark index + unknown for message contains nothing
        const contents = leadsCategorized?.reduce((res, item, index) => {
          const content = item?.content?.message?.text;
          if (content) {
            const { comments, leadsInComments } = getCommentsCategorize(item?.feedback?.id);
            subLeads.push([...leadsInComments]);

            res.push({ uid: item?.feedback?.id, content: content?.slice(0, MAX_CHARACTERS), comments });
            feedbackIdsWattingForCategorize.push(...[item?.feedback?.id, ...comments?.map((item) => item?.uid)]);
          } else {
            subLeads.push([]);
            leadsCategorized[index].type = CATEGORIES.UNKNOWN;
          }
          return res;
        }, []);

        // Combine comments leads
        leadsCategorized = leadsCategorized?.reduce((res, item, index) => {
          res.push(...[item, ...subLeads[index]]);
          return res;
        }, []);

        if (feedbackIdsWattingForCategorize?.length !== 0) {
          // Check websocket connection + auto renew if connection has been closed
          await checkActiveOfWS();
          // Categorize
          const { success, data, shouldStop } = await categorizeContents(
            contents,
            leadSettingRef?.current?.contents?.map((item) => item?.description)
          );

          if (success && data) {
            setCategorizePayload({
              feedbackIdsWattingForCategorize,
              leadsCategorized,
            });
            return { success: true, isWatingForCategorize: true };
          }
          if (!success && shouldStop) {
            // Got limit AI request per day
            gotExceedLimitAI.current = true;
            return { success: false, shouldStop };
          }
        }

        // Exclude leads not match with filter
        const filteredLeads = leadsCategorized?.filter(
          (item) => leadSettingRef?.current?.options?.categorize?.indexOf(item?.type) !== -1
        );

        return {
          success: true,
          leadsCategorized,
          filteredLeads,
        };
      } catch (error) {
        console.log(error);
        return { succes: false };
      }
    },
    [addToLeadStats]
  );

  const getLeadsInComments = async (leads = [], skipValidate = false) => {
    try {
      let posts = [...leads];

      // Check validate
      if (!skipValidate) {
        const SUITABLE_RELATIONS = {
          seller: 'buyer',
          buyer: 'seller',
        };

        // Check the post have related to
        posts = leads?.filter(
          (item) => leadSettingRef?.current?.options?.categorize?.indexOf(SUITABLE_RELATIONS[item?.type]) !== -1
        );
      }

      let results = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const post of posts) {
        let res = null;
        // ONly get comments post have content
        if (post?.content?.message?.text) {
          // eslint-disable-next-line no-await-in-loop
          res = await getCommentsFromFeedback(
            post?.feedback?.id,
            10,
            null,
            (comment) =>
              comment?.actor?.id !== post?.actor?.id &&
              comment?.content?.message?.text &&
              removeEmoji(comment?.content?.message?.text || '')
          );
        }
        if (res) {
          results = results.concat(res);
          if (post?.feedback?.id) {
            results = results?.map((item) => ({
              ...item,
              parent: {
                id: post?.feedback?.id,
                content: post?.content?.message?.text || '',
              },
            }));
          }
        }
      }

      return formatLeadsPending(results, 'comment');
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getExcludesData = useCallback(() => {
    const initial = {
      comment: [],
      inbox: [],
      add_friend: [],
      info: excudeReadFacebookInfoRef?.current || [],
    };

    return leadsResultRef?.current?.reduce((res, item) => {
      const actorId = item?.actor?.id;
      ['inbox', 'add_friend', 'info'].forEach((key) => {
        if (item[key] && res[key]?.indexOf(actorId) === -1) {
          res[key].push(actorId);
        }
      });

      return res;
    }, initial);
  }, [leadsResult]);

  const onReceiveCategorizeResult = (data) => {
    const { feedbackIdsWattingForCategorize, leadsCategorized } = categorizePayloadRef?.current || {
      feedbackIdsWattingForCategorize: [],
      leadsCategorized: [],
    };

    const { results } = data || { results: [] };
    // Update type
    feedbackIdsWattingForCategorize?.forEach((uid) => {
      const idx = leadsCategorized?.findIndex((item) => item?.feedback?.id === uid);
      const index = results?.findIndex((item) => item?.uid === uid);
      if (idx !== -1) {
        leadsCategorized[idx].type = results[index]?.target_type || CATEGORIES.UNKNOWN;
      }
    });

    if (results) {
      // Store stats
      addToLeadStats(STATS_NAME.CATEGORIZE, results?.length || 0);
    }
    const filteredLeads = leadsCategorized?.filter(
      (item) => leadSettingRef?.current?.options?.categorize?.indexOf(item?.type) !== -1
    );
    onRepareDataThenStart(leadsCategorized, filteredLeads);
  };

  // DELAY RENDER LEADS RESULT ON TABLE
  const [leadResultsQueue, setLeadResultsQueue, leadResultsQueueRef] = useRefCustom([]);
  const queueTimerRef = useRef();

  useEffect(() => {
    if (queueTimerRef?.current) {
      clearTimeout(queueTimerRef?.current);
    }
    queueTimerRef.current = setTimeout(() => {
      if (leadResultsQueueRef?.current?.length !== 0) {
        const sliced = leadResultsQueueRef?.current?.slice(0, DEFAULT_LEAD_AMOUNT);
        const others = leadResultsQueueRef?.current?.slice(DEFAULT_LEAD_AMOUNT, leadResultsQueueRef?.current?.length);
        setLeadsResult([...leadsResultRef?.current, ...(sliced || [])]);
        setLeadResultsQueue([...others]);
      }
      clearTimeout(queueTimerRef?.current);
    }, DELAY_TIME_RENDER_LEADS);
  }, [leadResultsQueue]);

  const markAsExceededLimit = () => {
    gotExceedLimitAI.current = true;
  };

  const markAsRunning = () => {
    setIsExecutingLeadsSeeding(true);
  };

  const onRepareDataThenStart = (leadsCategorized = [], filteredLeads = []) => {
    if (gotExceedLimitAI?.current) {
      runPrepareData();
      return;
    }

    addToLimitCrawledLeads(leadsCategorized?.length || 0);

    // remove out pending list
    const excludeList = leadsCategorized?.map((item) => item?.feedback?.id);
    setLeadsPending([...leadsPendingRef?.current]?.filter((item) => excludeList?.indexOf(item?.feedback?.id) === -1));

    // Leads in result but not run before +  match with filter
    const leadsResultRemains = getLeadsRemain();

    setLeadResultsQueue([...leadResultsQueueRef?.current, ...(leadsCategorized || [])]);

    const splited = [...leadsResultRemains, ...(filteredLeads || [])]?.slice(0, DEFAULT_LEAD_AMOUNT);

    if (splited?.length !== 0) {
      taskRef.current = setTimeout(async () => {
        console.log('Start seeding tasks!');
        const { isOperationCanceled } =
          (await leadsSeeding(
            {
              contents: leadSettingRef?.current?.contents,
              leads: splited?.map((item) => ({
                feedback: item?.feedback?.id,
                uid: item?.actor?.id,
                isPage: item?.actor?.isPage,
                content: item?.content?.message?.text,
                parent: item?.parent,
              })),
              options: leadSettingRef?.current?.options,
              excludesData: getExcludesData(),
              keyword: leadSettingRef?.current?.keyword,
            },
            receiveLeadsSeedingCallback,
            markAsExceededLimit,
            markAsRunning
          )) || {};

        // reset
        setIsExecutingLeadsSeeding(false);

        // Start next cycle
        console.log('Start next cycle');
        taskRef.current = null;
        if (isOperationCanceled) {
          console.log('Break cycle!! STOPPED.');
          onCancel();
          return;
        }

        runPrepareData();
        // Set 0 to execute right now when we collect enough data
        // DELAY_TIME_RENDER_LEADS + 1000 make sure result data have already data for update
      }, DELAY_TIME_RENDER_LEADS + 1000);
    } else {
      // Start next cycle
      console.log('Start next cycle');
      runPrepareData();
    }
  };

  const onRunSeedingLeads = async () => {
    // Countdown timer and execute data after sile to get enough amount leads
    const slicedLeads = leadsPendingRef?.current
      ?.filter((item) => item?.source === 'post')
      ?.slice(0, DEFAULT_LEAD_AMOUNT);

    // Check remain leads
    const leadsResultRemains = getLeadsRemain();
    if (leadsResultRemains?.length >= DEFAULT_LEAD_AMOUNT) {
      onRepareDataThenStart([], []);
      return;
    }

    console.log('Analyzing lead posts ...');
    // filteredLeads is leads matched, leadsCategorized is leads have categorized
    const { success, filteredLeads, leadsCategorized, isWatingForCategorize, shouldStop } = await categoryLeads(
      slicedLeads
    );
    if (!success) {
      // Notice message HERE
      console.error(tMessage('categorize_failed'));
      // if (shouldStop) {
      //   onCancel();
      //   return;
      // }
    }

    // Wating for categorize with AI
    if (isWatingForCategorize) {
      return;
    }

    onRepareDataThenStart(leadsCategorized, filteredLeads);
  };

  const onChangeLeadsPending = async () => {
    // console.log('Is amount of posts enough? - ', leadsPendingRef?.current?.length, allowRunTaskRef?.current);
    // Check enough amount posts need seeding
    if (allowRunTaskRef?.current) {
      const currentPosts = leadsPendingRef?.current?.filter((item) => item?.source === 'post');
      const leadsResultRemains = getLeadsRemain();

      // CHeck block crawl leads data
      const isBlockCrawl = getCurrentLeadsCrawled() >= DAILY_LIMIT_CRAWL;
      if (isCrawlLeads() && isBlockCrawl) {
        enqueueSnackbar(tMessage('limit_crawl', { amount: DAILY_LIMIT_CRAWL }), { variant: 'warning', persist: true });
        onCancel();
        return;
      }

      if (currentPosts?.length + leadsResultRemains?.length >= DEFAULT_LEAD_AMOUNT) {
        onRunSeedingLeads();
      } else if (!taskRef.current && leadSettingRef?.current?.keyword) {
        console.log('Get more data');
        clearTimeout(searchTaskRef?.current);

        const SEARCH_LEADS_DELAY = isCrawlLeads() ? ACTIONS_DELAY?.from * 1000 : DELAY_BETWEEN_SEARCH_POSTS;

        searchTaskRef.current = setTimeout(async () => {
          const res = await fetchListData();
          receiveLeadsPendingCallback(res);
        }, SEARCH_LEADS_DELAY);
      }
    }
  };

  const onStartLeadListen = async (data) => {
    try {
      if (!checkCommonData()) {
        return;
      }

      if (!canUseTier4) {
        enqueueSnackbar(tMessage('support_max'), { variant: 'error' });
        return;
      }

      const { timeGap, keyword, options, groupIds } = data;

      // Check have action inbox. => open messenger
      // if (options?.actions?.findIndex((item) => item?.value === ACTIONS.INBOX) !== -1) {
      //   // open messenger tab
      //   window.open(MESSENGER_URL, '_blank');
      // }

      const contents = await Promise.all(
        data?.contents?.map(async (item) => ({
          ...item,
          images: await Promise.all(item?.images?.map((file) => convertFileToUint8Array(file))),
        }))
      );

      onReset();

      setLeadSetting({ contents, timeGap, keyword, options, groupIds });
      setPaginationGroups(
        groupIds?.reduce((res, groupId) => {
          return {
            ...res,
            [groupId]: {
              cursor: null,
              hasNextPage: false,
            },
          };
        }, {})
      );

      isRunning.onTrue();
      noSleep?.enable();
      keepAlive?.turnOn();
      setAllowRunTask(contents?.length !== 0);
      runPrepareData(keyword);
    } catch (error) {
      console.log(error);
    }
  };

  const onReset = () => {
    setExcudeReadFacebookInfo([]);
    setCategorizePayload(null);
    setLeadsPending([]);
    setLeadsResult([]);
    setPagination({
      cursor: null,
      hasNextPage: false,
    });
    setPaginationGroups({});
  };

  const onCancel = () => {
    stopQueue(false, 'manual');
    clearInterval(taskRef.current);
    clearTimeout(searchTaskRef?.current);
    taskRef.current = null;
    setSearchTask(null);
    isRunning.onFalse();
    setAllowRunTask(false);
    noSleep?.disable();
  };

  const onRemoveStats = () => {
    localStorage.removeItem(STATS_STORAGE_NAME);
    setLeadStats([]);
    onReset();
  };

  return (
    <LeadContext.Provider
      value={useMemo(
        () => ({
          isRunning: isRunning?.value,
          leadsResult,
          leadStats,
          leadSetting,
          isExecutingLeadsSeeding,
          onStartLeadListen,
          onCancel,
          onRemoveStats,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRunning?.value, leadSetting, leadsResult, leadStats, onStartLeadListen]
      )}
    >
      {children}
    </LeadContext.Provider>
  );
}

export { LeadProvider, LeadContext, DEFAULT_TIME_GAP };
