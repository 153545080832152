import { jsonrepair } from 'jsonrepair';
import { uniqBy } from 'lodash';

/* eslint-disable */
export default class ExtractResponse {
  extractFbDstg(response) {
    try {
      const { raw } = response;
      const regex = /"token":"([^"]+)"/;
      const match = raw?.match(regex);

      if (match) {
        return match[1];
      } else {
        console.log('Token not found.');
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  extractProfile(response) {
    try {
      const { data } = response;

      const {
        id: profileUUID,
        profile_picture: { uri: avatar },
        name: username,
      } = data?.viewer?.actor || {};

      return (
        {
          profileUUID,
          avatar,
          username,
        } || null
      );
    } catch (error) {
      return null;
    }
  }

  extractUserProfileUID(response) {
    try {
      const { data } = response;

      const user = data?.user?.profile_header_renderer?.user;

      let name = user?.name;
      if (user?.alternate_name) {
        name = `${name} (${user?.alternate_name})`;
      }

      return (
        {
          id: user?.id,
          image: user?.profilePicLarge?.uri,
          name,
          url: user?.url,
        } || null
      );
    } catch (error) {
      return null;
    }
  }

  extractTagInGroup(response) {
    try {
      const { data } = response;
      const list = data?.comet_group_typeahead_search;
      return (list || [])?.filter((item) => item?.node);
    } catch (error) {
      return [];
    }
  }

  extractPresets(response) {
    try {
      const {
        categorized_text_format_presets: categorizedPresets,
        ranked_composer_text_format_presets: rankedPresets,
      } = response?.data?.viewer;

      const presets1 = rankedPresets?.map((item) => item?.preset);

      const presets2 = categorizedPresets?.reduce((res, item) => {
        const { name } = item;
        const data = item?.ranked_text_format_presets?.map((item) => ({ ...item?.preset, name }));
        res.push(...data);
        return res;
      }, []);

      return uniqBy([...presets1, ...presets2], 'preset_id');
    } catch (error) {
      return [];
    }
  }

  extractVideoUploadConfigs(response) {
    try {
      const { data } = response;

      const videoUploaderConfig = data?.viewer?.comet_composer_video_uploader_config || '';

      return JSON.parse(jsonrepair(videoUploaderConfig));
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  extractCheckPageProfile(response) {
    try {
      const userData = response?.data?.user?.comet_profile_entity_menu?.user || {};
      const delegatePageId = userData?.delegate_page?.id;

      const isPage = userData?.profile_type_name_for_content === 'PAGE';
      return { isPage, delegatePageId };
    } catch (error) {
      return { isPage: false };
    }
  }

  extractGroupsJoined(response) {
    try {
      const { data } = response;

      const {
        nonAdminGroups: {
          groups_tab: {
            tab_groups_list: { edges: otherGroups },
          },
        },
        adminGroups: {
          groups_tab: {
            tab_groups_list: { edges: yourGroups },
          },
        },
      } = data || {};

      return { results: [...yourGroups, ...otherGroups] || [] };
    } catch (error) {
      return null;
    }
  }

  extractFriends(response) {
    try {
      const { data } = response;

      const {
        user: {
          friends: { edges: friends },
        },
      } = data || {};

      return friends?.map((item) => item?.node);
    } catch (error) {
      return [];
    }
  }

  extractUsersOnBusiness(response) {
    try {
      const {
        o0: {
          data: { messaging_actors: users },
        },
      } = response;

      return users?.map((item) => ({
        uid: item?.id,
        name: item?.name,
        link: item?.url || `https://www.facebook.com/${item?.id}`,
        image: item?.big_image_src?.uri,
        customUID: item?.id,
      }));
    } catch (error) {
      return [];
    }
  }

  extractFollowers(response) {
    try {
      const { data } = response;
      const mainData = data?.node?.pageItems;

      const haveNextPage = mainData?.page_info?.has_next_page;
      const nextCursor = mainData?.page_info?.end_cursor;

      const list = mainData?.edges || [];

      return {
        data:
          list?.map((item) => {
            const {
              node,
              image: { uri },
              title: { text },
            } = item?.node;
            return {
              profile_picture: { uri },
              ...node,
              name: text,
            };
          }) || [],
        nextCursor: haveNextPage ? nextCursor : null,
      };
    } catch (error) {
      return {};
    }
  }

  extractAccessToken(response) {
    try {
      const { raw } = response;

      if (!raw) {
        return null;
      }
      const accessToken = raw.match(/(access_token=)+([A-Z])\w+/g);
      if (accessToken[0]) {
        return accessToken[0].split('=')[1];
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  extractCommentInPost(response) {
    try {
      const { data } = response;

      const feedback = data?.feedback;

      if (!feedback) {
        return null;
      }

      const commentInfo = feedback?.top_level_comments;

      const comments = commentInfo?.nodes?.map((item) => {
        const { __typename, id, name, profile_picture: profilePicture } = item?.author;
        return {
          feedbackId: item?.id,
          type: __typename,
          id,
          name,
          picture: profilePicture?.uri,
          body: item?.body,
        };
      });

      const pagination = commentInfo?.page_info || {};

      return {
        id: feedback?.id,
        owner: feedback?.owning_profile,
        comments,
        total_comments: commentInfo?.total_count || 0,
        pagination,
      };
    } catch (error) {
      return null;
    }
  }

  extractPostsInProfile(response) {
    try {
      const { data, paging } = response;

      return {
        data: data?.map((item) => item?.id) || [],
        paging,
      };
    } catch (error) {
      return null;
    }
  }

  extractReactionInPost(response) {
    try {
      const { data, paging } = response;

      const nextCursor = paging?.cursors?.after;

      return {
        data: data?.map((item) => item?.id) || [],
        nextCursor,
      };
    } catch (error) {
      return null;
    }
  }

  extractReactionInFeedback(response) {
    try {
      const { data } = response;

      const mainData = data?.node?.reactors;
      const haveNextPage = mainData?.page_info?.has_next_page;
      const nextCursor = mainData?.page_info?.end_cursor;

      const list = mainData?.edges || [];

      return {
        data: list?.map((item) => item?.node?.id) || [],
        nextCursor: haveNextPage ? nextCursor : null,
      };
    } catch (error) {
      return null;
    }
  }

  extractMessages(response) {
    try {
      const {
        viewer: {
          message_threads: { nodes },
        },
      } = response;

      return nodes || [];
    } catch (error) {
      return [];
    }
  }

  extractFeedbackData(response) {
    try {
      const { data } = response;

      const storyItems = data?.node?.group_feed?.edges?.filter((item) => item?.node?.__typename === 'Story');

      let feedBackIds = storyItems?.map((item) => ({
        content: item?.node?.comet_sections?.content?.story?.message?.text || '',
        link: item?.node?.comet_sections?.content?.story?.wwwURL,
        id: item?.node?.feedback?.id,
        cursor: item?.cursor,
      }));
      const cursors = feedBackIds?.map((item) => item?.cursor).filter((item) => item);

      return { feedBackIds, cursors };
    } catch (error) {
      return { feedBackIds: [], cursors: null };
    }
  }

  extractTimelinePosts(response) {
    try {
      const { data } = response;

      const list = data?.node?.timeline_list_feed_units?.edges || [];

      let feedBackIds = list?.map((item) => ({
        content: item?.node?.comet_sections?.content?.story?.message?.text || '',
        link: item?.node?.comet_sections?.content?.story?.wwwURL,
        id: item?.node?.feedback?.id,
        cursor: item?.cursor,
      }));
      const cursors = feedBackIds?.map((item) => item?.cursor).filter((item) => item);

      return { feedBackIds, cursors };
    } catch (error) {
      return { feedBackIds: [], cursors: null };
    }
  }

  extractPokeData(response, userId) {
    try {
      const { data } = response;
      return data?.user_poke?.user?.id == userId;
    } catch (error) {
      return false;
    }
  }

  extractRemoveFriend(response, friendId) {
    try {
      const { data } = response;
      return data?.friend_remove?.unfriended_person?.id === friendId;
    } catch (error) {
      return false;
    }
  }

  extractRemoveSaleformat(response, storyId) {
    try {
      const { data } = response;
      return data?.product_item_removal_from_story?.story?.id === storyId;
    } catch (error) {
      return false;
    }
  }

  extractLikeData(response) {
    try {
      const { data } = response;
      return data?.feedback_react;
    } catch (error) {
      return null;
    }
  }

  extractCommentOfFeedbackData(response) {
    try {
      const { data } = response;

      const commentItems = data?.node?.comment_rendering_instance_for_feed_location?.comments?.edges || [];

      const pagination = data?.node?.comment_rendering_instance_for_feed_location?.comments?.page_info;

      const comments = commentItems?.map((item) => {
        // Actor
        const author = item?.node?.author;
        const actor = {
          ...author,
          profile_url: author?.url,
          profile_picture: { uri: author?.profile_picture_depth_0?.uri },
          isAnonymous: author?.__isEntity === 'GroupAnonAuthorProfile',
        };

        const content = {
          message: { ...item?.node?.body },
          url: item?.node?.feedback?.url,
        };

        return {
          content,
          actor,
          feedback: {
            id: item?.node?.feedback?.id,
            expansion_token: item?.node?.feedback?.expansion_info?.expansion_token,
            replies: {
              total_count: item?.node?.feedback?.replies_fields?.total_count,
              count: item?.node?.feedback?.replies_fields?.count,
            },
          },
        };
      });

      return { comments, pagination };
    } catch (error) {
      return { comments: [], pagination: null };
    }
  }

  extractPostsOfSearchInGroup(response) {
    try {
      const { data } = response;

      const postItems = data?.serpResponse?.results?.edges || [];

      const pagination = data?.serpResponse?.results?.page_info;

      const posts = postItems?.reduce((res, item) => {
        if (item?.node?.role !== 'GROUP_POSTS') {
          return res;
        }

        const story = item?.relay_rendering_strategy?.view_model?.click_model?.story;

        // Actor
        const actorData = story?.comet_sections?.context_layout?.story?.comet_sections?.actor_photo?.story;
        const actorInfo = actorData?.actors[0];
        const author = item?.node?.author;
        const actor = {
          ...actorInfo,
          profile_url: actorInfo?.profile_url,
          profile_picture: { uri: actorInfo?.profile_picture?.uri },
          isAnonymous: author?.__isEntity === 'GroupAnonAuthorProfile',
        };

        const content = story?.comet_sections?.content?.story?.comet_sections?.message_container?.story || {};
        content.url = story?.comet_sections?.content?.story?.wwwURL;

        const post = {
          content,
          actor,
          feedback: {
            id: story?.feedback?.id,
          },
        };

        return [...res, post];
      }, []);

      return { posts, pagination };
    } catch (error) {
      return { posts: [], pagination: null };
    }
  }

  extractRepliesOfComment(response) {
    try {
      const { data } = response;

      const replyItems = data?.node?.replies_connection?.edges || [];

      const pagination = data?.node?.replies_connection?.page_info;

      let replies = replyItems?.map((item) => ({
        content: item?.node?.body,
        link: item?.node?.feedback?.url,
        actor: item?.node?.author,
        feedback: {
          id: item?.node?.feedback?.id,
          expansion_token: item?.node?.feedback?.expansion_info?.expansion_token,
          replies: {
            total_count: item?.node?.feedback?.replies_fields?.total_count,
            count: item?.node?.feedback?.replies_fields?.count,
          },
        },
      }));

      return { replies, pagination };
    } catch (error) {
      return { replies: [], pagination: null };
    }
  }

  extractSearchPosts(response) {
    try {
      const { data: res } = response;
      const posts = res?.serpResponse?.results?.edges?.map((item) => {
        const { story } = item?.relay_rendering_strategy.view_model.click_model || {};
        const actorInfo = story?.comet_sections?.context_layout?.story?.comet_sections?.actor_photo?.story;
        const actor = actorInfo?.actors[0];
        actor.isAnonymous = actor?.__isEntity === 'GroupAnonAuthorProfile';
        // Detect page
        const trackingInfo = story?.comet_sections?.feedback?.story?.comet_feed_ufi_container?.story?.tracking || '';
        // actor.isPage = trackingInfo?.indexOf(`"page_id_type":"page"`) !== -1;
        const group = actorInfo?.to;
        const content = story?.comet_sections?.content?.story?.comet_sections?.message_container?.story || {};
        content.url = story?.comet_sections?.content?.story?.wwwURL;
        return {
          actor,
          group,
          content,
          feedback: story?.feedback,
        };
      });
      const pagination = res?.serpResponse?.results?.page_info;
      return { posts, pagination };
    } catch (error) {
      return { posts: [], pagination: null };
    }
  }

  extractDeclareVideoUploadInfomation(response) {
    try {
      if (!response) {
        return null;
      }
      const {
        lid,
        payload: { start_offset: startOffset, end_offset: endOffset, video_id: videoId },
      } = response;
      return { lid, startOffset, endOffset, videoId };
    } catch (error) {
      return null;
    }
  }

  extractPendingPosts(response) {
    try {
      const { data, errors } = response;

      const isRateLimit = (errors || [])?.some((item) => item?.message?.toLowerCase()?.indexOf('rate limit') !== -1);

      if (isRateLimit) {
        return { pendingPosts: [], pagination: null, isRateLimit };
      }

      const postItems = data?.node?.viewer_content?.contents?.edges || [];

      const pagination = data?.node?.viewer_content?.contents?.page_info;

      const pendingPosts = postItems?.map((item) => {
        const { id: storyId, comet_sections: cometSections } = item?.node || {};
        const actorId = cometSections?.content?.story?.actors[0]?.id;
        return { storyId, actorId };
      });

      return { pendingPosts, pagination };
    } catch (error) {
      return { pendingPosts: [], pagination: null };
    }
  }

  extractRemovePendingPost(response) {
    try {
      const { data } = response;
      return data?.story_delete?.deleted_story_id;
    } catch (error) {
      return null;
    }
  }

  extractParticipateApproved(response) {
    try {
      const { data, errors } = response;
      const isRateLimit = (errors || [])?.some((item) => item?.message?.toLowerCase()?.indexOf('rate limit') !== -1);

      return { isApproved: data?.group?.participation_model_info === null, isRateLimit };
    } catch (error) {
      console.log(error);
      return { isApproved: false };
    }
  }
}
